




























import { Component } from 'vue-property-decorator';
import { PleaseWaitRequest } from '@/dialog';
import { mixins } from 'vue-class-component';
import DialogMixin from '@/mixins/dialog-mixin';
import BaseDialog from './BaseDialog.vue';

@Component({
  components: {
    BaseDialog
  }
})
export default class PleaseWaitDialog extends mixins<DialogMixin<PleaseWaitRequest>>(DialogMixin) {

}
