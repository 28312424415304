

















































































































import { ActionsTypes } from '@/store';
import Vue from 'vue';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import DialogActions from './DialogActions.vue';

@Component({
  components: {
    DialogActions
  }
})
export default class BaseDialog extends Vue {
  @PropSync('showDialog') public shouldShow!: boolean;
  @Prop() public subHeader?: string;
  @Prop() public mimirIcon?: string;
  @Prop() public icon!: string;
  @Prop({default: false}) public bootstrapping?: boolean;
  @Prop({default: 'Header'}) public header!: string;
  @Prop({default: 300}) public maxWidth!: number;
  @Prop({default: 'Cancel'}) public cancelText?: string;
  @Prop({default: 'Ok'}) public actionText!: string;
  @Prop({default: false}) public disableAction!: boolean;
  @Prop({default: false}) public disableCancel!: boolean;
  @Prop({default: false}) public loading!: boolean;
  @Prop({default: false}) public infoOnly!: boolean;
  @Prop({default: false}) public closeOnActionClick!: boolean;
  @Prop({default: true}) public centeredContent!: boolean;
  @Prop({default: false}) public noAutoClose!:boolean;
  @Prop() public disabledTooltip?: string;
  @Prop({default: false}) public closeOnOutsideClick!:boolean;
  @Prop({default: ''}) public baseText!: string;
  @Prop({default: ''}) public baseHtml!: string;
  @Prop({default: false}) disableSecondaryAction!: boolean;
  @Prop() secondaryActionText?: string;
  @Prop({ default: 80 }) verticalHeightPct!: number;
  @Prop({default: null}) alert!: string | null;

  /**
   * Some extra events we emit for the case where a dialog is wrapped, this means the wrapping component
   * does not need to bind themselves to the shouldShow or showDialog props coming from outside just to know
   * whether a dialog is triggered or not.
   */
  @Watch('shouldShow')
  emitVisibility() {
    if (!this.shouldShow) {
      this.$emit('on-close');
    } else {
      this.$emit('on-show');
    }
  }

  mounted() {
    this.$store.dispatch(ActionsTypes.INCREASE_DIALOG_COUNTER);
  }

  beforeDestroy() {
    this.$store.dispatch(ActionsTypes.DECREASE_DIALOG_COUNTER);
  }

  get hasHeader(): boolean {
    return this.subHeader !== undefined;
  }

  doAction(emitString: string, ev?: MouseEvent) {
    this.$emit(emitString, ev);
    if (this.infoOnly) {
      this.doCancel();
    } else {
      if (this.closeOnActionClick) {
        this.shouldShow = false;
      }
    }
  }

  doCancel() {
    this.shouldShow = false;
    this.$emit('cancelClicked');
  }

  onEscape() {
    if (this.noAutoClose) {
      return;
    }

    this.shouldShow = false;
  }

  onOutsideClicked() {
    if (this.closeOnOutsideClick) {
      this.shouldShow = false;
    }
  }

  get hasDefault() {
    return this.$slots.default !== undefined;
  }
}
