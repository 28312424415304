































import { Component } from 'vue-property-decorator';
import { DialogCopyTextRequest } from '@/dialog';
import { mixins } from 'vue-class-component';
import DialogMixin from '@/mixins/dialog-mixin';
import CopyButton from '../CopyButton.vue';

@Component({
  components: {
    CopyButton
  }
})
export default class CopyTextDialog extends mixins<DialogMixin<DialogCopyTextRequest>>(DialogMixin) {

}
