import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { themes } from '../theme';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: { dark: themes[1], light: themes[0] },
  },
});
