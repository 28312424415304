




import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Title extends Vue {
  @Prop({ default: false }) isClear!: boolean;
  title = '';

  @Watch('isClear')
  onDataChanged(){
    this.title = '';
  }

  changeTitle() {
    this.$emit('triggerTitle', this.title);
  }
}
