import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Layout from '@/pages/layout/Layout.vue';
import Dashboard from '@/admin/Dashboard.vue';
import SystemSetting from '@/admin/components/SystemSetting.vue';
import History from '@/pages/History.vue';
import { routePathMaps } from './routeData';

Vue.use(VueRouter);
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Upload',
    component: Layout,
    meta: { title: 'Q-Upload' },
    children: [
      {
        path: routePathMaps.userSetting.path,
        name: routePathMaps.userSetting.name,
        // route level code-splitting
        // this generates a separate chunk (settings.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/pages/Settings.vue'),
      },
      {
        path: routePathMaps.systemSetting.path,
        name: routePathMaps.systemSetting.name,
        component: SystemSetting,
      },
      {
        path: routePathMaps.dashBoard.path,
        name: routePathMaps.dashBoard.name,
        component: Dashboard,
      },
      {
        path: routePathMaps.history.path,
        name: routePathMaps.history.name,
        component: History,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
