import { JSONSchemaType } from 'ajv';
import { DynamoDB } from 'aws-sdk';
import { ISODateTimeString } from 'aws-sdk/clients/iotwireless';
import { DateRangeType } from './dateRangeDto';
import { CoreSingleDataResponse, EventPathParameters, ExclusiveStartKeyType, validatePayloadSchema } from './genericDto';

export enum ActivityStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export interface BasicPayload {
  tenantId: string;
  userId: string;
}

export type Tenant = Omit<BasicPayload, 'userId'>;

export type SystemActivityStatus = { status?: string };

export interface BasicPayloadFileActivity extends Tenant {
  userId?: string;
  itemState?: string;
}

export interface BasicPayloadFileActivityWithTitle extends BasicPayloadFileActivity {
  title?: string;
}

export type FilterApiHistoryPayload = BasicPayload & Partial<DateRangeType> & SystemActivityStatus;

export interface GetActivityPayload extends BasicPayload {
  fromDate?: ISODateTimeString;
  toDate?: ISODateTimeString;
  limit?: number;
  exclusiveStartKey?: ExclusiveStartKeyType;
}
export interface GetSystemActivityPayload extends GetActivityPayload {
  status?: string;
}

export type GetActivityPayloadWithStatus = GetActivityPayload & SystemActivityStatus;

export type GetAllUserActivityPayload = Omit<GetSystemActivityPayload, 'userId' | 'requesterId'>;

export type ActivityFilteringPayload = Omit<GetActivityPayload, 'requesterId' | 'limit' | 'exclusiveStartKey' | 'userId'> & BasicPayloadFileActivityWithTitle;

export interface GetActivityResponse<T> {
  results?: Partial<T>[];
  exclusiveStartKey: DynamoDB.DocumentClient.Key | undefined;
}
export interface SingleActivityResponse extends CoreSingleDataResponse {
  httpMethod?: string;
  path?: string;
  status?: string;
  pathParameters?: EventPathParameters | null;
  functionName?: string;
  queryStringParameters?: EventPathParameters | null;
  error?: string | null;
  body?: string | null;
}

export function validateGetActivityPayload(payload: GetSystemActivityPayload) {
  const getSystemActivityPayloadSchema: JSONSchemaType<GetSystemActivityPayload> = {
    type: 'object',
    properties: {
      tenantId: { type: 'string', nullable: false },
      userId: { type: 'string', nullable: true },
      fromDate: { type: 'string', format: 'date', nullable: true },
      toDate: { type: 'string', format: 'date', nullable: true },
      limit: { type: 'number', nullable: true },
      exclusiveStartKey: {
        type: 'object',
        nullable: true,
        properties: {
          pk: { type: 'string' },
          sk: { type: 'string' },
          gs1pk: { type: 'string', nullable: true },
          gs1sk: { type: 'string', nullable: true },
        },
        required: ['pk', 'sk'],
      },
      status: { type: 'string', nullable: true },
    },
    required: ['tenantId'],
  };

  return validatePayloadSchema<GetSystemActivityPayload>(getSystemActivityPayloadSchema, payload);
}
