import { JSONSchemaType } from 'ajv';
import { GetItemHistoryPayload, ItemState } from './itemModels';
import { GetMdfFileFieldsSchema, GetMdfFileViewsItemSchema, GetMdfFileViewsUploadSchema, MappingDataSchema } from './mimirDto';
import { CoreSingleDataResponse, validatePayloadSchema } from './genericDto';
import { FieldValue } from './mdfModels';

export type MetadataType = {
  [key: string]: Array<string> | string | undefined | Record<string, any>;
};

export type JsonData = {
  formData: MetadataType;
};

export interface metadataDto {
  metadata: any;
}

export type MimirMetadataDto = {
  metadata: {
    formData: MetadataType;
    formId: string;
  };
};

export type MimirMdfCacheDto = {
  mdf: GetMdfFileFieldsSchema[];
  mappingData: MappingDataSchema;
  mdfItems: GetMdfFileViewsItemSchema[];
  mdfUpload: GetMdfFileViewsUploadSchema[];
  mdfIdRoh: string;
};

export const DefaultMimirCache = (): MimirMdfCacheDto => {
  return {
    mdf: [],
    mappingData: {},
    mdfItems: [],
    mdfUpload: [],
    mdfIdRoh: '',
  };
};

export interface metadataBody {
  pk: string;
  sk: string;
  mapping?: MappingDataSchema;
}

export interface mappingBody extends metadataBody {}
export interface mappingDto {
  mapping: MappingDataSchema;
}

export interface BaseMetadataDto {
  title: string;
  itemState: ItemState;
  isDeleting?: boolean;
  mediaType?: string;
  mediaSize?: number;
}
export interface MetadataDto extends BaseMetadataDto {
  id: string;
}

interface MetadataResponseWithFileId extends BaseMetadataDto {
  fileId: string;
}

export type GetMetadataHistoryPayload = Omit<GetItemHistoryPayload, 'itemState' | 'title'>;

export type MetadataResponse = MetadataResponseWithFileId & CoreSingleDataResponse;

export type MetadataUpdatePayload = Pick<MetadataDto, 'id' | 'itemState'>;

export const validateCreateMetadataPayload = (payload: BaseMetadataDto): string => {
  const createMetadataSchema: JSONSchemaType<BaseMetadataDto> = {
    type: 'object',
    properties: {
      title: { type: 'string', nullable: false },
      itemState: { type: 'string', nullable: false },
      isDeleting: { type: 'boolean', nullable: true },
      mediaType: { type: 'string', nullable: true },
      mediaSize: { type: 'number', nullable: true },
    },
    required: ['title', 'itemState'],
  };

  return validatePayloadSchema<BaseMetadataDto>(createMetadataSchema, payload);
};

export const validateMetadataUpdatePayload = (payload: MetadataUpdatePayload): string => {
  const createMetadataSchema: JSONSchemaType<MetadataUpdatePayload> = {
    type: 'object',
    properties: {
      id: { type: 'string', nullable: false },
      itemState: { type: 'string', nullable: false },
    },
    required: ['id', 'itemState'],
  };

  return validatePayloadSchema<MetadataUpdatePayload>(createMetadataSchema, payload);
};

export const validateGetMetadataHistoryPayload = (payload: GetMetadataHistoryPayload): string => {
  const getFileHistoryPayloadSchema: JSONSchemaType<GetMetadataHistoryPayload> = {
    type: 'object',
    properties: {
      tenantId: { type: 'string', nullable: false },
      userId: { type: 'string', nullable: false },
      fromDate: { type: 'string', format: 'date', nullable: true },
      toDate: { type: 'string', format: 'date', nullable: true },
      limit: { type: 'integer', nullable: true },
      exclusiveStartKey: {
        type: 'object',
        nullable: true,
        properties: {
          pk: { type: 'string' },
          sk: { type: 'string' },
          gs1pk: { type: 'string', nullable: true },
          gs1sk: { type: 'string', nullable: true },
        },
        required: ['pk', 'sk'],
      },
    },
    required: ['tenantId', 'userId'],
  };

  return validatePayloadSchema<GetMetadataHistoryPayload>(getFileHistoryPayloadSchema, payload);
};

type Part = { ETag: string; PartNumber: number };

export interface CompleteMultipartUploadDto {
  itemId: string;
  parts: Part[];
  itemMetaData: {
    formId: string;
    generated_description?: string;
    formData: Record<string, FieldValue | unknown>;
  };
}

export const validatedCompleteMultipartUploadPayload = (payload: CompleteMultipartUploadDto) => {
  const createCompleteMultipartUploadSchema: JSONSchemaType<CompleteMultipartUploadDto> = {
    type: 'object',
    properties: {
      itemId: { type: 'string', nullable: false },
      parts: { type: 'array', items: { type: 'object', properties: { ETag: { type: 'string', nullable: false }, PartNumber: { type: 'number', nullable: false } }, required: ['ETag', 'PartNumber'] } },
      itemMetaData: {
        type: 'object',
        properties: { formId: { type: 'string', nullable: false }, generated_description: { type: 'string', nullable: true }, formData: { type: 'object', nullable: false } },
        required: ['formId', 'formData'],
      },
    },
    required: ['itemId', 'parts'],
  };

  return validatePayloadSchema<CompleteMultipartUploadDto>(createCompleteMultipartUploadSchema, payload);
};
