import Vue from 'vue';

const eventBus = new Vue();
export default eventBus;

export const EVENTS = {
  HIDE_DIALOG: 'HideDialog',
  SHOW_DIALOG: 'ShowDialog',
  SEARCH_PAGE_RESULTS_LOADED: 'SearchPage_Results_Loaded',
  SEARCH_PAGE_RESET: 'SearchPage_Reset',
  SHOW_TOAST: 'showToast',
  DO_SEARCH: 'doSearch',
  RESET_DATEFILTER: 'resetDateFilter',
  START_ANALYTICS: 'startAnalytics',
  CANCEL_ANALYTICS_JOB: 'stopAnalyticsJob',
  PLAYER_JUMP_TO_TIME: 'playerJumpToTime',
  SHOW_TAB_IN_ITEM_DETAILS: 'showTabInItemDetails',
  SHOW_TIMED_MDF_MENU: 'showTimedMdfMenu',
  SET_PLAYER_CURRENTTIME_TO_FOCUSED_WORD: 'setPlayerCurrentTimeToFocusedWord',
  DOWNLOAD_AUDIO_CHANNELS: 'downloadAudioChannels',
  REFRESH_CURRENT_SEARCH: 'refreshCurrentSearch',
  SHOW_FOLDER_CONTEXT_MENU: 'showFolderContextMenu',
  SHOW_MULTIPLE_ITEMS_CONTEXT_MENU: 'showMultipleItemsContextMenu',
  CONFIG_RESET: 'configReset',
  NAVIGATE_BACK_FROM_ITEM: 'navigateBackFromItemDetails',
  TRIGGER_RELATIONS_REFRESH_IF_FOCUSED: 'triggerRelationsRefreshIfFocused',
  RESET_DRAG_ZONE: 'resetDragZone',
  SHOW_TIMELINE_TRACK_CONTEXTMENU: 'showTimelineTrackContextMenu'
};

export const TOAST = {
  DEFAULT: '#06677d',
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning',
};

export const DONT_AUTO_HIDE_TOAST = -1;
