import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { DialogRequest } from '@/dialog';

@Component
export default class DialogMixin<T extends DialogRequest> extends Vue {
  @Prop() req!: T;

  protected showDialog = false;
  protected loading = this.req.loading ?? false;

  mounted() {
    this.showDialog = true;
  }

  onCancel() {
    this.showDialog = false;
  }

  @Watch('showDialog')
  onDialogShowHide() {
    if (!this.showDialog) {
      this.$emit('on-close');
    }
  }

  @Watch('req.showDialog', { immediate: true })
  onShowDialogChange(newValue: boolean, oldValue: boolean) {
    this.showDialog = newValue;
  }
}
