import { decodeBase64 } from '../util/general';

export interface ParsedJwt {
  header: any;
  payload: any;
}

export function parseJwt(token: string): ParsedJwt {
  const tokenParts = token.split('.');
  return {
    header: JSON.parse(decodeBase64(tokenParts[0].replace(/-/g, '+').replace(/_/g, '/'))),
    payload: JSON.parse(decodeBase64(tokenParts[1].replace(/-/g, '+').replace(/_/g, '/'))),
  };
}

export function getUserEmailFromJwt(token: string): string | undefined {
  const parsedToken = parseJwt(token);
  return parsedToken?.payload?.email;
}
