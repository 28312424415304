import store, { GettersTypes } from '@/store';
import { TenantUserListMap } from '@qmu/common/dto/userDataDto';
import { isEmpty } from 'lodash';

export const getUserIdFromMappingData = (tenant: string, userData: string) => {
  const useEmailToUserIdMap = store.getters[GettersTypes.GET_TENANT_USER_LIST_EMAIL_USER_ID_MAP] as TenantUserListMap;
  const useNameToUserIdMap = store.getters[GettersTypes.GET_TENANT_USER_LIST_USER_NAME_USER_ID_MAP] as TenantUserListMap;

  if (!isEmpty(useEmailToUserIdMap[tenant][userData])) return useEmailToUserIdMap[tenant][userData];
  if (!isEmpty(useNameToUserIdMap[tenant][userData])) return useNameToUserIdMap[tenant][userData];
  return userData;
};

export const getUserEmailFromUserId = (tenant: string, userId: string) => {
  const useIdEmailMap = store.getters[GettersTypes.GET_TENANT_USER_LIST_USER_ID_USER_EMAIL_MAP] as TenantUserListMap;
  if (!isEmpty(useIdEmailMap[tenant][userId])) return useIdEmailMap[tenant][userId];
  return 'Deleted User';
};
