










import { UserDataModel, UserPoolDataModel, UserPoolData } from '@/common/userPoolData';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import { allAvailableGroups, UserType } from '@qmu/common/enum/UserTypes';
import store, { GettersTypes, ActionsTypes } from '@/store';

interface UserGroupItemModel {
  name: string;
  srNo: number;
  memberShip: boolean;
}

@Component
export default class GroupHandling extends Vue {
  @Prop({ default: null }) public userDataModel!: UserDataModel | null;
  @Prop({ default: false }) public applyClicked!: boolean;
  userPoolData: UserPoolDataModel = new UserPoolData();
  loading = false;
  tenantList: string[] = [];
  memberOf: string[] = [];
  initialItems: Array<UserGroupItemModel> = [];
  updatableItems: Array<UserGroupItemModel> = [];

  created() {
    this.fetchUserGroupInfo();
  }

  async getPromisesOfAssignOrRemove(removableItems: string[], assignableItems: string[], userName: string, tenantId: string, userId: string): Promise<Array<boolean>> {
    return Promise.all([
      ...removableItems.map(async item => {
        return this.userPoolData.removeUserFromGroup(userName, item, tenantId, userId);
      }),
      ...assignableItems.map(async item => {
        return this.userPoolData.assignUserToGroup(userName, item, tenantId, userId);
      }),
    ]);
  }

  @Watch('applyClicked')
  async submitRequest() {
    if (!this.userDataModel?.username || !this.userDataModel?.userId || !this.userDataModel?.tenantId || !this.userDataModel?.groups) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Something is not right. Please refresh your browser and try again', TOAST.ERROR);
      return;
    }
    this.loading = true;
    const removableItems = this.getRemovableGroups(this.initialItems, this.updatableItems);
    const assignableItems = this.getAssignableGroups(this.initialItems, this.updatableItems);
    const resp = await this.getPromisesOfAssignOrRemove(removableItems, assignableItems, this.userDataModel.username, this.userDataModel.tenantId, this.userDataModel.userId);

    const success = !resp.some(status => !status);

    if (success && (removableItems.length || assignableItems.length)) {
      this.userDataModel.groups.length = 0;
      this.userDataModel.groups.push(...this.getAllUpdatedGroups(this.updatableItems));
      EventBus.$emit(EVENTS.SHOW_TOAST, 'User group info updated successfully', TOAST.SUCCESS);
    } else if (!success) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Something went wrong. Please retry', TOAST.ERROR);
    } else {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'No changes applied', TOAST.INFO);
    }

    this.loading = false;
    this.$dialog.hideGroupHandlingDialog();
  }

  getHeaders() {
    return [
      { text: 'Serial #', value: 'srNo', align: 'center' },
      { text: 'Group Name', value: 'name', align: 'center' },
      { text: 'MemberShip', value: 'memberShip', align: 'center' },
    ];
  }

  async getItems(tenantList: string[]) {
    return tenantList.map((item, index) => {
      return {
        name: item,
        srNo: index + 1,
        memberShip: this.memberOf.includes(item),
      };
    });
  }

  async getMemberOf() {
    return this.userDataModel
      ? (await this.userPoolData.getGroupsOfUser(this.userDataModel.username)).filter(el => {
          return this.tenantList?.includes(el);
        })
      : [];
  }

  getAllowedGroups(forGroup: UserType) {
    if (forGroup === UserType.TENANT_ADMIN || store.getters[GettersTypes.GET_SELECTED_TENANT] as string !== 'qbics') {
      const availableGroups = allAvailableGroups();
      if (availableGroups.includes(UserType.SUPER_ADMIN)) {
        availableGroups.splice(availableGroups.indexOf(UserType.SUPER_ADMIN), 1);
      }
      return availableGroups;
    }
    if (forGroup === UserType.SUPER_ADMIN) return allAvailableGroups();
    return [];
  }

  async fetchUserGroupInfo() {
    this.loading = true;
    try {
      this.tenantList = this.getAllowedGroups(store.getters[GettersTypes.GET_USER_GROUP] as UserType);
      this.memberOf = await this.getMemberOf();
      this.updatableItems = await this.getItems(this.tenantList);
      this.initialItems = cloneDeep(this.updatableItems); // clone deep to avoid reference issues
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'User group info fetching failed', TOAST.ERROR);
      this.initialItems.length = 0;
      this.updatableItems.length = 0;
      this.memberOf.length = 0;
      this.tenantList.length = 0;
    }
    this.loading = false;
  }

  getAllUpdatedGroups(updatableItems: Array<UserGroupItemModel>) {
    const updatedGroups: string[] = [];
    updatableItems.forEach(el => {
      if (el.memberShip) updatedGroups.push(el.name);
    });
    return updatedGroups;
  }

  getRemovableGroups(initialItems: Array<UserGroupItemModel>, updatableItems: Array<UserGroupItemModel>): Array<string> {
    const removableGroups: string[] = [];
    initialItems.forEach(el => {
      if (el.memberShip && updatableItems.find(item => item.name === el.name && !item.memberShip)) {
        removableGroups.push(el.name);
      }
    });
    return removableGroups;
  }

  getAssignableGroups(initialItems: Array<UserGroupItemModel>, updatableItems: Array<UserGroupItemModel>): Array<string> {
    const assignableGroups: string[] = [];
    initialItems.forEach(el => {
      if (!el.memberShip && updatableItems.find(item => item.name === el.name && item.memberShip)) {
        assignableGroups.push(el.name);
      }
    });
    return assignableGroups;
  }
}
