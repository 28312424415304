import Ajv, { JSONSchemaType } from 'ajv';
import addFormats from 'ajv-formats';
import { DynamoDB } from 'aws-sdk';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import { ValidateSchema } from '../enum/genericTypes';

export interface GetResponseWithExclusiveKey {
  results: DocumentClient.ItemList;
  exclusiveStartKey?: DynamoDB.DocumentClient.Key;
}
export interface ExclusiveStartKeyType {
  pk: string;
  sk: string;
  gs1pk?: string;
  gs1sk?: string;
}
export interface EventPathParameters {
  [name: string]: string | undefined;
}

export interface FileNameAndExtension {
  name: string;
  extension: string;
}

export const EntityTypes = ['CONFIG', 'ITEM', 'STATUS', 'INFO'] as const;
export type EntityType = typeof EntityTypes[number];
export interface CoreSingleDataResponse {
  id?: string;
  type?: EntityType;
  modifiedOn?: string;
  createdOn?: string;
  createdBy?: string;
  modifiedBy?: string;
}

/**
 * validate any schema payload
 * @param schema is JSONSchemaType<T>
 * @param payload type is generic <T>
 * @return string 'validated' or error message as string.
 */
export function validatePayloadSchema<T>(schema: JSONSchemaType<T>, payload: T) {
  const ajv = new Ajv();
  addFormats(ajv);
  const validate = ajv.compile(schema);
  validate(payload);

  if (validate.errors && validate.errors.length > 0) return validate.errors.map(error => error.message).join(',');
  return ValidateSchema.VALIDATED;
}
