













































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import { BasicPayload } from '@qmu/common/dto/activityModels';
import { ExclusiveStartKeyType } from '@qmu/common/dto/genericDto';
import { post } from '@/common/rest';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { DateRangeType } from '@qmu/common/dto/dateRangeDto';
import { HandleLoadMetadataHistoryPayload } from '@/admin/dto/handleLoadActivityDto';
import { changeDateTimeFormatToEuropean } from '@qmu/common/util/formatData';
import { splitNameAndExtension } from '@qmu/common/util/general';
import { makeMetadataJsonFileName } from '@/utils/fileUtilities';
import { GetFileActivityPayload } from '@qmu/common/dto/itemModels';
import FromToDate from '@/admin/components/activityFields/FromToDate.vue';
import ClearFilter from '@/admin/components/activityFields/ClearFilter.vue';
import { isEmpty } from 'lodash';

@Component({
  components: {
    FromToDate,
    ClearFilter,
  },
})
export default class MetadataHistory extends Vue {
  @Prop() readonly receivedPayload!: BasicPayload;
  @Prop({ default: false }) disableApplyBtn!: Boolean;
  @Prop({ default: false }) viewAllFile!: Boolean;
  fileActivity: object[] | null = null;
  isFileActivityLoading: boolean = false;
  viewFileActivity: boolean = false;
  tenantId: string = '';
  userId: string = '';
  isEnd: boolean = true;
  pageNumber: number = 0;
  slOffset: number = 1;
  currentFromDate: string | undefined;
  currentToDate: string | undefined;
  currentExclusiveStartKey: ExclusiveStartKeyType | null = null;
  lastExclusiveStartKey: ExclusiveStartKeyType | null = null;
  listExclusiveStartKey: ExclusiveStartKeyType[] = [];
  itemState: string = '';
  isTriggerClear = false;

  async created() {
    this.viewFileActivity = false;
    this.isEnd = true;
    this.pageNumber = 0;
    this.slOffset = 1;
    this.listExclusiveStartKey = [];
  }

  clearFiltering() {
    this.isTriggerClear = !this.isTriggerClear;
    this.getRecentActivity();
  }

  get triggerClear() {
    return this.isTriggerClear;
  }

  get defaultLimit() {
    return store.getters[GettersTypes.GET_TENANT_CONFIG_MAX_HISTORY];
  }

  get metadataHistoryFlag() {
    return store.getters[GettersTypes.GET_METADATA_HISTORY_FLAG];
  }

  set metadataHistoryFlag(value: boolean) {
    store.dispatch(ActionsTypes.SET_METADATA_HISTORY_FLAG, value);
  }

  get metadataXmpFlag() {
    return store.getters[GettersTypes.GET_METADATA_FROM_XMP_FLAG];
  }

  set metadataXmpFlag(value: boolean) {
    store.dispatch(ActionsTypes.SET_METADATA_XMP_FLAG, value);
  }

  @Watch('receivedPayload')
  onDataChanged() {
    this.getFileActivity(this.receivedPayload);
  }

  getRecentActivity() {
    this.getFileActivity(this.receivedPayload);
  }

  changeToJsonExtension(title: string) {
    const { name } = splitNameAndExtension(title);
    const extension = 'json';
    return `${name}.${extension}`;
  }

  repeatMetadata(fileId: string) {
    const jsonFileName = makeMetadataJsonFileName(fileId);
    this.$emit('triggerMetadataRepeat', jsonFileName);
  }

  changeDateFormat(value: string) {
    return changeDateTimeFormatToEuropean(value);
  }

  async getFileActivity(dataParams: BasicPayload) {
    this.tenantId = dataParams.tenantId;
    this.userId = dataParams.userId;
    this.pageNumber = 0;
    this.slOffset = 1;
    await this.handleLoadActivity({
      pageNumber: 1,
      isInitialLoad: false,
      isPrevious: false,
      isNext: false,
      tenantId: this.tenantId,
      userId: this.userId,
      limit: this.defaultLimit,
    });
  }

  async loadFileActivityWithDateRange(dateRange: DateRangeType) {
    this.currentFromDate = dateRange.fromDate;
    this.currentToDate = dateRange.toDate;
    if (this.currentToDate < this.currentFromDate || isEmpty(this.currentFromDate) || isEmpty(this.currentToDate)) return;
    this.slOffset = 1;
    this.listExclusiveStartKey = [];
    this.currentExclusiveStartKey = this.lastExclusiveStartKey = null;
    await this.handleLoadActivity({
      pageNumber: 1,
      isInitialLoad: false,
      isPrevious: false,
      isNext: false,
      tenantId: this.tenantId,
      userId: this.userId,
      fromDate: dateRange.fromDate,
      toDate: dateRange.toDate,
      limit: this.defaultLimit,
    });
  }

  async previousPage() {
    if (!this.currentExclusiveStartKey) return;
    const exclusiveStartKey = this.listExclusiveStartKey.pop();
    await this.handleLoadActivity({
      pageNumber: this.pageNumber - 1,
      isInitialLoad: false,
      isPrevious: true,
      isNext: false,
      tenantId: this.tenantId,
      userId: this.userId,
      fromDate: this.currentFromDate,
      toDate: this.currentToDate,
      limit: this.defaultLimit,
      exclusiveStartKey: exclusiveStartKey,
    });
  }

  async nextPage() {
    if (!this.currentExclusiveStartKey) return;
    await this.handleLoadActivity({
      pageNumber: this.pageNumber + 1,
      isInitialLoad: false,
      isPrevious: false,
      isNext: true,
      tenantId: this.tenantId,
      userId: this.userId,
      fromDate: this.currentFromDate,
      toDate: this.currentToDate,
      limit: this.defaultLimit,
      exclusiveStartKey: this.currentExclusiveStartKey,
    });
  }

  async handleLoadActivity(parms: HandleLoadMetadataHistoryPayload): Promise<void> {
    this.isFileActivityLoading = true;
    this.viewFileActivity = true;

    if (parms.isPrevious) parms.isNext = false;
    if (parms.isNext) parms.isPrevious = false;

    try {
      const response = await this.getActivity({
        tenantId: parms.tenantId,
        userId: parms.userId,
        fromDate: parms.fromDate,
        toDate: parms.toDate,
        limit: parms.limit,
        exclusiveStartKey: parms.exclusiveStartKey,
      });
      if (response.status !== 200) throw 'Cannot load metadata history.';
      this.fileActivity = response.data.data.results;

      if (this.fileActivity?.length === 0) {
        EventBus.$emit(EVENTS.SHOW_TOAST, 'User do not have any history.', TOAST.INFO);
        this.isEnd = true;
      }

      if (parms.isInitialLoad) {
        this.isEnd = true;
      } else {
        if (!response.data.data.exclusiveStartKey) {
          this.isEnd = true;
          if (parms.isNext) this.listExclusiveStartKey.push(this.lastExclusiveStartKey!);
        } else {
          this.isEnd = false;
          if (parms.isPrevious) this.lastExclusiveStartKey = parms.exclusiveStartKey!;
          else {
            this.listExclusiveStartKey.push(this.lastExclusiveStartKey!);
            this.lastExclusiveStartKey = this.currentExclusiveStartKey;
          }
          this.currentExclusiveStartKey = response.data.data.exclusiveStartKey;
        }
      }

      this.pageNumber = parms.pageNumber;
      if (this.pageNumber <= 1) this.lastExclusiveStartKey = null;
      if (parms.limit) {
        if (parms.isNext) this.slOffset += parms.limit;
        if (parms.isPrevious) this.slOffset -= parms.limit;
      }
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Cannot load metadata history', TOAST.WARNING);
    } finally {
      this.isFileActivityLoading = false;
    }
  }

  async getActivity(parms: GetFileActivityPayload) {
    const serviceLinks = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
    const url = serviceLinks.metadataHistory;
    const response = await post(url, { data: parms });

    return response;
  }

  clearData() {
    this.fileActivity = [];
    this.isEnd = true;
    EventBus.$emit(EVENTS.SHOW_TOAST, 'Clear Data!', TOAST.WARNING);
    this.pageNumber = 0;
    this.slOffset = 1;
    this.listExclusiveStartKey = [];
    this.currentExclusiveStartKey = this.lastExclusiveStartKey = null;
  }
}
