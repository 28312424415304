











import { Component, Vue } from 'vue-property-decorator';
import store, { ActionsTypes, GettersTypes } from '@/store';

@Component
export default class SelectTenant extends Vue {
  tenantList: Array<string> = [];

  async created() {
    this.tenantList = store.getters[GettersTypes.GET_TENANT_LIST];
  }
  get selectedTenant() {
    return store.getters[GettersTypes.GET_SELECTED_TENANT];
  }

  set selectedTenant(value: string | null) {
    store.dispatch(ActionsTypes.SET_SELECTED_TENANAT, value);
  }
}
