












































import { Component, Vue } from 'vue-property-decorator';
import MetaData from './components/MetaData.vue';
import ApiActivity from './components/SystemActivity.vue';
import FileActivity from './components/UserActivity.vue';
import UserGroups from './components/UserGroups.vue';

import store from '../store';
import { GettersTypes } from '../store';
import { UserInfo } from '@qmu/common/dto/UserInfoDto';
import { UserType } from '@qmu/common/enum/UserTypes';
import { DashboardTabs } from '@/utils/enum/dashboard';
@Component({
  components: {
    MetaData,
    ApiActivity,
    FileActivity,
    UserGroups,
  },
})
export default class AdminDashboard extends Vue {
  tabs: Array<string> | null = null;
  user = UserType;

  async created() {
    this.user = UserType;
  }

  get currentTab(){
    return this.tabs;
  }

  get userGroup() {
    return store.getters[GettersTypes.GET_USER_GROUP] as UserInfo;
  }

  get dashboardTabs() {
    return DashboardTabs;
  }
}
