






















import { Component, Vue, Prop } from 'vue-property-decorator';
import LoadUser from './LoadUser.vue';
import { ActivityFilteringPayload } from '@qmu/common/dto/activityModels';
import store, { GettersTypes } from '@/store';
import { UserType } from '@qmu/common/enum/UserTypes';
import { UserInfo } from '@qmu/common/dto/UserInfoDto';
import FileHistory from '@/components/FileHistory.vue';
import UserList from './activityFields/UserList.vue';
import Title from './activityFields/Title.vue';
import FromToDate from './activityFields/FromToDate.vue';
import FileActivityStatus from './activityFields/FileActivityStatus.vue';
import FilterBtn from './activityFields/FilterBtn.vue';
import ClearFilter from './activityFields/ClearFilter.vue';
import { DateRangeType } from '@qmu/common/dto/dateRangeDto';
import { getUserIdFromMappingData } from '@/utils/mappingUtilities';
import { isEmpty } from 'lodash';
import EventBus, { EVENTS, TOAST } from '@/eventBus';

@Component({
  components: {
    LoadUser,
    FileHistory,
    UserList,
    Title,
    FromToDate,
    FileActivityStatus,
    FilterBtn,
    ClearFilter,
  },
})
export default class FileActivity extends Vue {
  @Prop({ default: '' }) currentTab!: string;
  viewFileActivity = false;
  viewAllFile = false;
  dataPayload: ActivityFilteringPayload | null = null;

  filterTitle = '';
  filterSelectedUser = '';
  filterFromDate = '';
  filterToDate = '';
  filterFileStatus = '';
  isTriggerClear = false;

  get userType() {
    return UserType;
  }

  get triggerClear() {
    return this.isTriggerClear;
  }

  setSelectedUser(selectedUser: string) {
    this.filterSelectedUser = selectedUser;
  }

  setFilterTitle(title: string) {
    this.filterTitle = title;
  }

  setFromToDate(fromToDate: DateRangeType) {
    this.filterFromDate = fromToDate.fromDate;
    this.filterToDate = fromToDate.toDate;
  }

  setFileStatus(selectedFileStatus: string) {
    this.filterFileStatus = selectedFileStatus;
  }

  applyFiltering() {
    const selectedTenantId = store.getters[GettersTypes.GET_SELECTED_TENANT];
    const userId = this.userGroup === this.userType.NORMAL_USER ? store.getters[GettersTypes.GET_USER_ID] : getUserIdFromMappingData(selectedTenantId, this.filterSelectedUser);
    if (this.filterFromDate && this.filterToDate && isEmpty(userId) && isEmpty(this.filterFileStatus)) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Please select an user or status', TOAST.WARNING);
      return;
    }
    this.getFileActivity({
      tenantId: selectedTenantId,
      userId: userId,
      title: this.filterTitle,
      fromDate: this.filterFromDate,
      toDate: this.filterToDate,
      itemState: this.filterFileStatus,
    });
  }

  clearFiltering() {
    this.isTriggerClear = !this.isTriggerClear;

    this.filterTitle = '';
    this.filterSelectedUser = '';
    this.filterFromDate = '';
    this.filterToDate = '';
    this.filterFileStatus = '';
    this.applyFiltering();
  }

  get userGroup() {
    return store.getters[GettersTypes.GET_USER_GROUP] as UserInfo;
  }

  mounted() {
    const selectedTenantId = store.getters[GettersTypes.GET_SELECTED_TENANT];
    this.getFileActivity({ tenantId: selectedTenantId });
  }

  getFileActivity(dataParams: ActivityFilteringPayload) {
    if (this.userGroup === this.userType.NORMAL_USER) {
      dataParams.tenantId = store.getters[GettersTypes.GET_TENANT_ID];
      dataParams.userId = store.getters[GettersTypes.GET_USER_ID];
    }
    this.viewAllFile = this.userGroup !== this.userType.NORMAL_USER;
    this.viewFileActivity = true;
    this.sendBasicPayload = dataParams;
  }

  set sendBasicPayload(payload: ActivityFilteringPayload | null) {
    this.dataPayload = payload;
  }

  get isViewAllFile() {
    return this.viewAllFile;
  }

  get sendBasicPayload() {
    return this.dataPayload;
  }

  backToListUser(view: boolean) {
    this.viewFileActivity = view;
  }
}
