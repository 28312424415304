import { get, post } from '@/common/rest';
import store, { GettersTypes } from '@/store';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import { GetMdfsInfoResponse, GetMdfFileFieldsSchema, GetMdfFileRespone, GetMdfFileQueryParamPayload, MdfsItemInfo, MappingDataSchema, GetMdfsAndMappingsSchema } from '@qmu/common/dto/mimirDto';
import { getSupportedMdfTypes } from '@qmu/common/metaDataSetting/mimir';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
const suppportedMdfTypes: Array<string> = getSupportedMdfTypes();

/**
 * Fetches MDF info from the mdf info link store in STORE and returns and array
 * of supported MdfsItemInfo.
 * @returns Promise<Array<MdfsItemInfo>>
 */
export async function fetchMdfsInfo(): Promise<Array<MdfsItemInfo>> {
  try {
    const resp = await get(store.getters[GettersTypes.GET_MDF_INFO_LINK]);
    const mdfsInfo: GetMdfsInfoResponse = resp.data.data;
    if (!mdfsInfo._embedded.collection) throw new Error(`MDF FILE FETCHING FAILED. Returened ${mdfsInfo}`);
    const mdfItems: Array<MdfsItemInfo> = mdfsInfo._embedded.collection.filter(item => {
      return suppportedMdfTypes.includes(item.label.toLowerCase());
    });
    return mdfItems;
  } catch (err) {
    EventBus.$emit(EVENTS.SHOW_TOAST, 'MDF file info fething failed from mimir', TOAST.ERROR);
  }
  return [];
}

/**
 * Fetch MDF JSON by MDFID that we received from fetchMdfsInfo() item. and
 * return its fields.
 * @param mdfId
 * @returns Array<GetMdfFileFieldsSchema>
 */
export async function fetchMDFFields(mdfId: string): Promise<Array<GetMdfFileFieldsSchema>> {
  try {
    const getMdfParamPayload: GetMdfFileQueryParamPayload = { mdfId };
    const response = await get(store.getters[GettersTypes.GET_MDF_FILE_LINK], { params: getMdfParamPayload });
    if (!response) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Something Went Wrong', TOAST.ERROR);
      return [];
    }
    const mdfResponse: GetMdfFileRespone = response.data.data;
    return mdfResponse.fields;
  } catch (error) {
    EventBus.$emit(EVENTS.SHOW_TOAST, 'Something Went Wrong in fetching mdf', TOAST.ERROR);
    return [];
  }
}

/**
 * Fetch mapping data of the specified mdfFileName. Must be from supported mdf types.
 * @throws Error().response.status = 404 if mdf mapping not found
 * @throws Error() AxiosError on request failed
 * @param mdfFileName case insensitive file name
 * @param mdfFileFields
 * @returns
 */
export async function fetchMapping(mdfFileName: string): Promise<MappingDataSchema> {
  mdfFileName = mdfFileName.toLowerCase();
  const response = await get(store.getters[GettersTypes.GET_MDF_MAPPING_LINK], { params: { file: mdfFileName } });
  if (response.data.data.mapping) return response.data.data.mapping;
  return {};
}

/**
 * Populate empty mapping with mdfFile field.id as key and make post request on create mapping endpoint.
 * If the post request does not throw any error, it is considered as mapping creation successful.
 * @param mdfFileName mdfFileName
 * @param mdfFileFields received from fetchMDF()
 * @returns true if successful else false
 */
export async function createMapping(mdfFileName: string, mdfFileFields: Array<GetMdfFileFieldsSchema>): Promise<boolean> {
  const newMapping: MappingDataSchema = {};
  mdfFileFields.forEach((field: GetMdfFileFieldsSchema) => {
    newMapping[field.id] = {
      xmpFieldName: '',
      readOnly: false,
      visibility: true,
      defaultDateFlag: false,
      required: false,
    };
  });

  try {
    if (Object.keys(newMapping).length === 0) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Something Went Wrong', TOAST.ERROR);
      return false;
    }
    await post(store.getters[GettersTypes.GET_MDF_CREATE_MAPPING_LINK], { data: newMapping, params: { file: mdfFileName } });
    EventBus.$emit(EVENTS.SHOW_TOAST, 'New Mapping Created', TOAST.SUCCESS);
    return true;
  } catch (error) {
    EventBus.$emit(EVENTS.SHOW_TOAST, 'Mapping creation failed', TOAST.ERROR);
  }
  return false;
}

/**
 * Update mapping that was fetched using fetchMapping and modified/not_modified after fetching.
 * It will make a post request on updateMapping API endpoint if the request doesnt throw any error,
 * it is considered as successful.
 * @param mdfFileName
 * @param mappingData fetched and/or modified from fetchMapping()
 * @returns
 */
export async function updateMappings(mdfFileName: string, mappingData: MappingDataSchema): Promise<boolean> {
  let keys = Object.keys(mappingData);
  let trimmedMappingData: MappingDataSchema = {};
  keys.forEach((key: string) => {
    if (mappingData[key]) {
      trimmedMappingData[key] = {
        xmpFieldName: mappingData[key].xmpFieldName ? mappingData[key].xmpFieldName.trim() : '',
        readOnly: mappingData[key].readOnly,
        visibility: mappingData[key].visibility,
        defaultDateFlag: mappingData[key].defaultDateFlag,
        required: mappingData[key].required,
      };
    } else {
      trimmedMappingData[key] = {
        xmpFieldName: '',
        readOnly: false,
        visibility: mappingData[key].visibility,
        defaultDateFlag: mappingData[key].defaultDateFlag,
        required: mappingData[key].required,
      };
    }
  });

  try {
    if (!mappingData) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Mapping data not found', TOAST.ERROR);
      return false;
    }
    await post(store.getters[GettersTypes.GET_MDF_UPDATE_MAPPING_LINK], { data: trimmedMappingData, params: { file: mdfFileName } });
    EventBus.$emit(EVENTS.SHOW_TOAST, 'Mapping Updated', TOAST.SUCCESS);
    return true;
  } catch (error) {
    EventBus.$emit(EVENTS.SHOW_TOAST, 'Mapping Update Failed', TOAST.ERROR);
    return false;
  }
}

/**
 * Fetch Mdf fields and Mappings from the GET_MDFS_AND_MAPPING_LINK Endpoint
 * @param mdfTypes
 * @returns
 * @throws Error() if result not found
 */
export async function fetchMdfsAndMapping(mdfTypes: Array<string>): Promise<GetMdfsAndMappingsSchema> {
  const resp = await post(store.getters[GettersTypes.GET_MDFS_AND_MAPPING_LINK], { data: mdfTypes });
  const mdfsAndMaps: GetMdfsAndMappingsSchema = resp.data.data;
  if (mdfsAndMaps || Object.keys(mdfsAndMaps).length > 0) return mdfsAndMaps;
  throw new Error(`MDFs and Mapping fetching error for : ${mdfTypes}`);
}

export const fetchMdfId = async (mdfTypes: Array<string>) => {
  try {
    const response = await post((store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks).getMdfIds, { data: mdfTypes });
    if (!response.data) throw new Error('Error fetching MDF IDs');
    return response.data.data;
  } catch (error) {
    EventBus.$emit(EVENTS.SHOW_TOAST, 'Error fetching MDF IDs', TOAST.WARNING);
  }
};
