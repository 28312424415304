import { JSONSchemaType } from 'ajv';
import { ItemMetaData } from './mdfModels';
import { CoreSingleDataResponse, ExclusiveStartKeyType, validatePayloadSchema } from './genericDto';
import { FileTrackerDto } from './FileTrackerDto';
import { BasicPayloadFileActivityWithTitle, GetActivityPayload } from './activityModels';

export enum ItemState {
  NEW = 'new',
  PROGRESSING = 'progressing',
  COMPLETE = 'complete',
  ERROR = 'error',
  CANCELLED = 'cancelled',
}

export enum ItemType {
  VIDEO = 'video',
  IMAGE = 'image',
  PERSON = 'person',
  AUDIO = 'audio',
  FILE = 'file',
  FOLDER = 'folder',
}

export function isItemType(it: string): it is ItemType {
  return Object.values(ItemType).includes(it as ItemType);
}

interface Progression {
  done: number;
  known: number;
}

export type UploadProgressInfo = {
  files: Progression;
  bytes: Progression;
};

export interface ItemDto {
  // Directly reflected entity attribute
  id: string;
  title: string;

  userEmail: string;
  notificationStatus: boolean;
  isEmailSent: boolean;
  // Directly reflected entity attributes
  itemState: ItemState;
  isDeleting?: boolean;
  mediaType?: string;
  mediaFrameRate?: number;

  // Directly reflected entity attribute.
  metadata: ItemMetaData;
  uploadProgressInfo?: UploadProgressInfo | null;
  originalFileName: string;
  itemType?: ItemType;

  // Directly reflected entity attribute.
  mediaSize?: number;

  purgeDate?: string;
  uploadId?: string;
  fileTracker?: FileTrackerDto;
  modifiedBy?: string;
}

export type ItemCreate = Omit<ItemDto, 'id'>;

/*
What does -? mean in TypeScript?
+ or - allows control over the mapped type modifier (? or readonly). -? means must be all present, aka it removes optionality (?)
https://stackoverflow.com/questions/52417131/what-does-mean-in-typescript
https://www.typescriptlang.org/docs/handbook/2/mapped-types.html#mapping-modifiers

[K in keyof T]-? this property (K) is valid only if it has the same name as any property of T.
Required<Pick<T, K>> makes a new type from T with just the current property in the iteration, and marks it as required
Partial<Pick<T, Exclude<keyof T, K>>> makes a new type with all the properties of T, except from the property K.
& is what unites the type with only one required property from Required<...> with all the optional properties from Partial<...>.
[keyof T] ensures that only properties of T are allowed.

https://docs.microsoft.com/en-us/javascript/api/@azure/keyvault-certificates/requireatleastone?view=azure-node-latest
*/
export type RequireAtLeastOne<T> = { [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>> }[keyof T];

export type ItemUpdate = RequireAtLeastOne<ItemCreate>;
export interface GetItemHistoryPayload {
  tenantId: string;
  userId: string;
  title?: string;
  fromDate?: string;
  toDate?: string;
  limit?: number;
  exclusiveStartKey?: ExclusiveStartKeyType;
  itemState?: ItemState;
}

export type GetAllItemHistoryPayload = Omit<GetItemHistoryPayload, 'userId'>;

export interface ItemHistoryResponse<T> {
  results: Partial<T>[];
}

export type GetFileActivityPayload = Partial<GetActivityPayload> & Partial<Pick<BasicPayloadFileActivityWithTitle, 'itemState' | 'title'>>;

export interface SingleItemResponse extends CoreSingleDataResponse {
  title?: string;
  itemState?: ItemState;
  userEmail?: string;
  notificationStatus?:boolean;
  isEmailSent?: boolean;
  uploadId?: string;
  metadata?: ItemMetaData;
  fileId: string;
  fileTrackers: FileTrackerDto;
}

export interface SingleUploadSignedUrlResponse {
  url: string;
}

export interface SingleUploadSignedUrlPayload {
  fileName: string;
}

export const validateGetItemHistoryPayload = (payload: GetItemHistoryPayload): string => {
  const getFileHistoryPayloadSchema: JSONSchemaType<GetItemHistoryPayload> = {
    type: 'object',
    properties: {
      tenantId: { type: 'string', nullable: false },
      userId: { type: 'string', nullable: true },
      title: { type: 'string', nullable: true },
      fromDate: { type: 'string', format: 'date', nullable: true },
      toDate: { type: 'string', format: 'date', nullable: true },
      limit: { type: 'integer', nullable: true },
      itemState: { type: 'string', enum: Object.values(ItemState), nullable: true },
      exclusiveStartKey: {
        type: 'object',
        nullable: true,
        properties: {
          pk: { type: 'string' },
          sk: { type: 'string' },
          gs1pk: { type: 'string', nullable: true },
          gs1sk: { type: 'string', nullable: true },
        },
        required: ['pk', 'sk'],
      },
    },
    required: ['tenantId'],
  };

  return validatePayloadSchema<GetItemHistoryPayload>(getFileHistoryPayloadSchema, payload);
};
