








import { Component, Vue } from 'vue-property-decorator';
import { BasicPayload } from '@qmu/common/dto/activityModels';
import store, { ActionsTypes, GettersTypes } from '@/store';
import MetadataHistory from '@/components/MetadataHistory.vue';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import { get } from '@/common/rest';
import { MetadataType } from '@qmu/common/dto/MetadataModels';
import { isEmpty } from 'lodash';
import { abortAllFiles } from '@/utils/fileUtilities';

@Component({
  components: {
    MetadataHistory,
  },
})
export default class History extends Vue {
  dataPayload: BasicPayload | null = null;
  jsonFile: string | null = null;
  disableBtn = false;
  isSessionEmpty = true;

  mounted() {
    const tenantId = store.getters[GettersTypes.GET_TENANT_ID];
    const userId = store.getters[GettersTypes.GET_USER_ID];
    this.disableBtn = false;
    this.isSessionEmpty = true;
    this.listFileActivity({
      userId: userId,
      tenantId: tenantId,
    });
  }

  listFileActivity(dataParams: BasicPayload) {
    this.sendBasicPayload = dataParams;
  }

  set sendBasicPayload(payload: BasicPayload | null) {
    this.dataPayload = payload;
  }

  get sendBasicPayload() {
    return this.dataPayload;
  }

  get disableApplyButton() {
    return this.disableBtn;
  }

  repeatMetadata(jsonFile: string) {
    this.jsonFile = jsonFile;
    const allFileStatus = store.getters[GettersTypes.GET_ALL_FILE_STATUS];
    if (!isEmpty(allFileStatus)) {
      this.showWarning();
      this.isSessionEmpty = false;
    } else this.getMetaDataFromS3();
  }

  showWarning() {
    this.$dialog.showSimpleAsyncDialog(
      {
        closeOnActionClick: true,
        icon: 'mdi-alert',
        maxWidth: 400,
        header: 'Warning!',
        baseHtml: 'All current session activity will be cleared!',
      },
      async () => this.getMetaDataFromS3() // okay action, so fetch data.
    );
  }

  async getMetaDataFromS3() {
    if (!this.jsonFile) return;
    this.disableBtn = true;
    try {
      const baseUrl = store.getters[GettersTypes.GET_METADATA_ON_S3_LINK];
      const queryParams = new URLSearchParams({ fileName: this.jsonFile }).toString();
      const url = `${baseUrl}?${queryParams}`;
      const response = await get(url);
      const metadataFieldValues = response.data.data.metadata.formData;
      this.saveMetaDataToStore(metadataFieldValues ? metadataFieldValues : {});
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Cannot load Metadata credentials.', TOAST.WARNING);
      this.disableBtn = false;
    }
  }

  async saveMetaDataToStore(metadataFieldValues: MetadataType) {
    store.dispatch(ActionsTypes.SET_METADATA_FIELDS, metadataFieldValues);
    if (!this.isSessionEmpty) await this.resetStoreCurrentSessionUpload();
    this.$router.push('/');
  }

  async resetStoreCurrentSessionUpload() {
    abortAllFiles();
    // reset upload session store value (fileTrackers, files, fileStatus, uploadStart, metadataFieldStatus)
    store.dispatch(ActionsTypes.SET_FILE_TRACKER, []);
    store.dispatch(ActionsTypes.SET_FILES, []);
    store.dispatch(ActionsTypes.SET_FILE_STATUS, {});
    store.dispatch(ActionsTypes.UPDATE_UPLOAD_START, true);
    store.dispatch(ActionsTypes.UPDATE_METADATA_FIELD_STATUS, false);
  }
}
