import { UserType } from '@qmu/common/enum/UserTypes';

type routePathMapType = { name: string; path: string };
export interface routePathMapsType {
  userSetting: routePathMapType;
  systemSetting: routePathMapType;
  dashBoard: routePathMapType;
  history: routePathMapType;
}

export const routePathMaps: routePathMapsType = {
  history: { name: 'History', path: '/history' },
  dashBoard: { name: 'Dashboard', path: '/dashboard' },
  userSetting: { name: 'User settings', path: '/userSettings' },
  systemSetting: { name: 'System settings', path: '/systemSetting' },
};

export const getListOfPaths = () => ['/'].concat(Object.values(routePathMaps).map(item => item.path));

export const protectedPathForGroups: Record<UserType, Array<string>> = {
  [UserType.SUPER_ADMIN]: ['/', routePathMaps.userSetting.path, routePathMaps.systemSetting.path, routePathMaps.history.path, routePathMaps.dashBoard.path],
  [UserType.TENANT_ADMIN]: ['/', routePathMaps.userSetting.path, routePathMaps.systemSetting.path, routePathMaps.history.path, routePathMaps.dashBoard.path],
  [UserType.NORMAL_USER]: ['/', routePathMaps.userSetting.path, routePathMaps.history.path, routePathMaps.dashBoard.path],
  [UserType.READONLY_USER]: ['/'],
};
