var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{class:{
    'mobile': _vm.$vuetify.breakpoint.smAndDown,
    'tablet': _vm.$vuetify.breakpoint.md,
  },attrs:{"id":"qmu","flat":""}},[(_vm.authenticated && _vm.userGroup !== _vm.userType.READONLY_USER)?_c('v-app-bar',{attrs:{"color":"#364551","app":"","flat":""}},[_c('v-container',{staticClass:"py-0 fill-height"},[_c('v-card',{staticClass:"ml-1 mr-6",attrs:{"max-width":"130","flat":"","color":"transparent"}},[_c('v-img',{attrs:{"src":require("../../assets/qbics-logo.png")}})],1),_c('v-btn-toggle',{attrs:{"mandatory":"","group":""},model:{value:(_vm.selectedAppBarIndex),callback:function ($$v) {_vm.selectedAppBarIndex=$$v},expression:"selectedAppBarIndex"}},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var navigate = ref.navigate;
return [_c('v-btn',{key:"Upload",staticClass:"no-uppercase",attrs:{"text":"","color":"white","role":"link"},on:{"click":navigate}},[_vm._v("Upload")])]}}],null,false,1866608425)}),_c('router-link',{attrs:{"to":_vm.routeMaps.history.path,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var navigate = ref.navigate;
return [_c('v-btn',{key:_vm.routeMaps.history.name,staticClass:"no-uppercase",attrs:{"text":"","color":"white","role":"link"},on:{"click":navigate}},[_vm._v(_vm._s(_vm.routeMaps.history.name))])]}}],null,false,3570126116)}),_c('router-link',{attrs:{"to":_vm.routeMaps.dashBoard.path,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var navigate = ref.navigate;
return [_c('v-btn',{key:_vm.routeMaps.dashBoard.name,staticClass:"no-uppercase",attrs:{"text":"","color":"white","role":"link"},on:{"click":navigate}},[_vm._v(_vm._s(_vm.routeMaps.dashBoard.name))])]}}],null,false,180431972)}),_c('router-link',{attrs:{"to":_vm.routeMaps.userSetting.path,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var navigate = ref.navigate;
return [_c('v-btn',{key:_vm.routeMaps.userSetting.name,staticClass:"no-uppercase",attrs:{"text":"","color":"white","role":"link"},on:{"click":navigate}},[_vm._v(_vm._s(_vm.routeMaps.userSetting.name))])]}}],null,false,3787208164)}),(_vm.userGroup !== _vm.userType.NORMAL_USER)?_c('router-link',{attrs:{"to":_vm.routeMaps.systemSetting.path,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var navigate = ref.navigate;
return [_c('v-btn',{key:_vm.routeMaps.systemSetting.name,staticClass:"no-uppercase",attrs:{"text":"","color":"white","role":"link"},on:{"click":navigate}},[_vm._v(_vm._s(_vm.routeMaps.systemSetting.name))])]}}],null,false,4131066404)}):_vm._e()],1),_c('v-spacer'),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-palette")])],1)]}}],null,false,2528481156)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.enableTheme('light')}}},[_vm._v("Light "),_c('v-icon',{staticClass:"ml-5"},[_vm._v("mdi-white-balance-sunny")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.enableTheme('dark')}}},[_vm._v("Dark "),_c('v-icon',{staticClass:"ml-5"},[_vm._v("mdi-brightness-4")])],1)],1)],1)],1)],1),_c('v-avatar',{staticClass:"mr-10",attrs:{"size":"32"}},[_c('v-img',{attrs:{"src":_vm.getTenantLogo()}})],1),_c('v-btn',{attrs:{"small":"","dark":"","elevation":"0","color":"buttonPrimary"},on:{"click":_vm.signOut}},[_vm._v("Logout "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-logout")])],1)],1)],1):_vm._e(),_c('v-main',{staticClass:"bgColor"},[(_vm.authenticated)?_c('v-container',[(_vm.userGroup !== _vm.userType.READONLY_USER)?_c('v-row',[_c('v-col',[(_vm.userGroup === _vm.userType.SUPER_ADMIN && !_vm.isHomePage && (_vm.isDashboard || _vm.isSystemSetting))?_c('SelectTenant'):_vm._e(),(_vm.isHomePage)?_c('Upload'):_vm._e(),_c('router-view')],1)],1):_vm._e()],1):_c('v-container',{attrs:{"container--fluid":"","fill-height":"","pa-0":""}},[(_vm.isLoading)?_c('Loading'):_c('Login',{on:{"onLoggedIn":_vm.setAuthenticationStatus}})],1)],1),[_c('v-snackbar',{attrs:{"shaped":"","color":_vm.snackbar.color,"right":"","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
  var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":"","small":"","icon":""},on:{"click":function($event){_vm.snackbar.isVisible = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar.isVisible),callback:function ($$v) {_vm.$set(_vm.snackbar, "isVisible", $$v)},expression:"snackbar.isVisible"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")]),(_vm.dialogRequest)?_c('DialogFactory',{attrs:{"req":_vm.dialogRequest},on:{"on-close":function($event){_vm.dialogRequest = null},"account-input":_vm.onAccountInput}}):_vm._e(),(_vm.waitDialogRequest)?_c('PleaseWaitDialog',{attrs:{"req":_vm.waitDialogRequest},on:{"on-close":function($event){_vm.waitDialogRequest = null}}}):_vm._e(),(_vm.simpleDialogRequest)?_c('SimpleDialog',{attrs:{"req":_vm.simpleDialogRequest},on:{"on-close":function($event){_vm.simpleDialogRequest = null}}}):_vm._e(),(_vm.editDialogRequest)?_c('EditDialog',{attrs:{"req":_vm.editDialogRequest},on:{"on-close":function($event){_vm.editDialogRequest = null}}}):_vm._e(),(_vm.groupHandlingDialogRequest)?_c('GroupHandlingDialog',{attrs:{"req":_vm.groupHandlingDialogRequest},on:{"on-close":function($event){_vm.groupHandlingDialogRequest = null}}}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }