export const getFileActivityDataTableHeaders = (viewAllFile: Boolean) => {
  const response = [
    { text: 'User Email', value: 'createdBy', sortable: false },
    { text: 'Title', value: 'title', sortable: false },
    { text: 'Progress', value: 'fileTrackers.realPercentage' },
    { text: 'Start Time', value: 'createdOn' },
    { text: 'End Time', value: 'modifiedOn', sortable: false },
    { text: 'Status', value: 'itemState' },
  ];
  if (!viewAllFile) response.splice(0, 1); // remove { text: 'User Email', value: 'createdBy' } for normal user
  return response;
};

export const getApiActivityDataTableHeaders = (isAllUserSearch: Boolean) => {
  const response = [
    { text: 'User Email', value: 'createdBy', sortable: false },
    { text: 'Requested Time', value: 'createdOn' },
    { text: 'Status', value: 'status' },
    { text: 'Task', value: 'functionName', sortable: false },
  ];
  if (!isAllUserSearch) response.splice(0, 1); // remove { text: 'User Email', value: 'createdBy' } for normal user
  return response;
};
