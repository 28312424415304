






















































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class S3ConfigModal extends Vue {
  DOMAIN_ORIGIN = window.location.origin;
  MINIFIED_CODE = `[{"AllowedHeaders":["Accept","Content-Type","qmu-access-token","Referer","sec-ch-ua","sec-ch-ua-mobile","sec-ch-ua-platform","User-Agent"],"AllowedMethods":["PUT"],"AllowedOrigins":["${this.DOMAIN_ORIGIN}"],"ExposeHeaders":["ETag"]}]`;
  STEPS = [
        "Log in to the Amazon S3 management console with your credentials.",
        "Locate and select your bucket.",
        "Click on the 'Permissions' button.",
        "Scroll down to look for the 'Cross-origin resource sharing (CORS)' section and click 'Edit' button.",
        "Copy the following CORS configuration, paste them and click 'Save changes'.",
      ];

  dialog = false;
  formattedJson = '';

  async mounted() {
    this.formattedJson = JSON.stringify(JSON.parse(this.MINIFIED_CODE), null, 2);
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {});
  }
}

