




import { ItemState } from '@qmu/common/dto/itemModels';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class FileActivityStatus extends Vue {
  @Prop({ default: false }) isClear!: boolean;
  itemStateList = Object.values(ItemState);
  selectedFileStatus = '';

  @Watch('isClear')
  onDataChanged(){
    this.selectedFileStatus = '';
  }

  changeSelectedFileStatus() {
    this.$emit('triggerSelectedFileStatus', this.selectedFileStatus);
  }
}
