













































import { Component, Vue } from 'vue-property-decorator';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import LoadUser from './LoadUser.vue';
import { FilterApiHistoryPayload, GetActivityPayloadWithStatus } from '@qmu/common/dto/activityModels';
import { ExclusiveStartKeyType } from '@qmu/common/dto/genericDto';
import { post } from '@/common/rest';
import store, { GettersTypes } from '@/store';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { DateRangeType } from '@qmu/common/dto/dateRangeDto';
import { HandleLoadActivityPayload } from '../dto/handleLoadActivityDto';
import { getApiActivityDataTableHeaders } from '@/common/dataTableHeaders';
import { changeDateTimeFormatToEuropean } from '@qmu/common/util/formatData';
import { isEmpty } from 'lodash';
import UserList from './activityFields/UserList.vue';
import FromToDate from './activityFields/FromToDate.vue';
import FilterBtn from './activityFields/FilterBtn.vue';
import ClearFilter from './activityFields/ClearFilter.vue';
import { getUserEmailFromUserId } from '@/utils/mappingUtilities';
import { getUserIdFromMappingData } from '@/utils/mappingUtilities';
import { functionNameMap, Method } from '../dto/methodName';
import SystemActivityStatus from './activityFields/SystemActivityStatus.vue';

@Component({
  components: {
    LoadUser,
    UserList,
    FromToDate,
    FilterBtn,
    ClearFilter,
    SystemActivityStatus,
  },
})
export default class ApiActivity extends Vue {
  userActivity: object[] = [];
  isUserActivityLoading = false;
  viewUserActivity = false;
  tenantId = '';
  userId = '';
  isEnd = true;
  pageNumber = 0;
  slOffset = 0;
  currentFromDate: string | undefined;
  currentToDate: string | undefined;
  currentStatus: string | undefined;
  currentExclusiveStartKey: ExclusiveStartKeyType | null = null;
  lastExclusiveStartKey: ExclusiveStartKeyType | null = null;
  listExclusiveStartKey: ExclusiveStartKeyType[] = [];
  isAllUserSearch = false;

  filterSelectedUser = '';
  filterFromDate = '';
  filterToDate = '';
  isTriggerClear = false;
  filterStatus = '';

  async mounted() {
    this.viewUserActivity = false;
    this.isEnd = true;
    this.pageNumber = 0;
    this.slOffset = 0;
    this.listExclusiveStartKey = [];
    const selectedTenantId = store.getters[GettersTypes.GET_SELECTED_TENANT];

    this.getUserActivityHistory({ tenantId: selectedTenantId, userId: '' });
  }

  get defaultLimit() {
    return store.getters[GettersTypes.GET_TENANT_CONFIG_MAX_HISTORY];
  }

  get triggerClear() {
    return this.isTriggerClear;
  }

  setSeletedStatus(selectedStatus: string) {
    this.filterStatus = selectedStatus;
  }

  setSelectedUser(selectedUser: string) {
    this.filterSelectedUser = selectedUser;
  }

  setFromToDate(fromToDate: DateRangeType) {
    this.filterFromDate = fromToDate.fromDate;
    this.filterToDate = fromToDate.toDate;
  }

  applyFiltering() {
    const selectedTenantId = store.getters[GettersTypes.GET_SELECTED_TENANT];
    const userId = getUserIdFromMappingData(selectedTenantId, this.filterSelectedUser);
    this.getUserActivityHistory({ tenantId: selectedTenantId, userId: userId, fromDate: this.filterFromDate, toDate: this.filterToDate, status: this.filterStatus });
  }

  clearFiltering() {
    this.isTriggerClear = !this.isTriggerClear;

    this.filterSelectedUser = '';
    this.filterFromDate = '';
    this.filterToDate = '';
    this.filterStatus = '';
    this.applyFiltering();
  }

  userEmail(userId: string) {
    return getUserEmailFromUserId(this.tenantId, userId);
  }

  get dataTableHeader() {
    return getApiActivityDataTableHeaders(this.isAllUserSearch);
  }

  generateFunctionName(path: string) {
    return path.split('/').pop();
  }

  getFunctionNameFromMap(functionName: keyof ServiceLinks, httpMethod: Method, path: string) {
    return functionNameMap[functionName] ? functionNameMap[functionName][httpMethod] : this.generateFunctionName(path);
  }

  changeDateFormat(value: string) {
    return changeDateTimeFormatToEuropean(value);
  }

  async getUserActivityHistory(dataParams: FilterApiHistoryPayload) {
    this.tenantId = dataParams.tenantId;
    this.userId = dataParams.userId;
    this.currentFromDate = dataParams.fromDate;
    this.currentToDate = dataParams.toDate;
    this.currentStatus = dataParams.status;
    this.isAllUserSearch = true;
    this.pageNumber = 0;
    this.slOffset = 0;
    await this.handleLoadActivity({
      pageNumber: 1,
      isInitialLoad: false,
      isPrevious: false,
      isNext: false,
      tenantId: this.tenantId,
      userId: this.userId,
      fromDate: this.currentFromDate,
      toDate: this.currentToDate,
      status: this.currentStatus,
      limit: this.defaultLimit,
    });
  }

  async previousPage() {
    if (!this.currentExclusiveStartKey) return;
    const exclusiveStartKey = this.listExclusiveStartKey.pop();
    await this.handleLoadActivity({
      pageNumber: this.pageNumber - 1,
      isInitialLoad: false,
      isPrevious: true,
      isNext: false,
      tenantId: this.tenantId,
      userId: this.userId,
      fromDate: this.currentFromDate,
      toDate: this.currentToDate,
      status: this.currentStatus,
      limit: this.defaultLimit,
      exclusiveStartKey: exclusiveStartKey,
    });
  }

  async nextPage() {
    if (!this.currentExclusiveStartKey) return;
    await this.handleLoadActivity({
      pageNumber: this.pageNumber + 1,
      isInitialLoad: false,
      isPrevious: false,
      isNext: true,
      tenantId: this.tenantId,
      userId: this.userId,
      fromDate: this.currentFromDate,
      toDate: this.currentToDate,
      status: this.currentStatus,
      limit: this.defaultLimit,
      exclusiveStartKey: this.currentExclusiveStartKey,
    });
  }

  async handleLoadActivity(parms: HandleLoadActivityPayload): Promise<void> {
    this.isUserActivityLoading = true;
    this.viewUserActivity = true;

    if (parms.isPrevious) parms.isNext = false;
    if (parms.isNext) parms.isPrevious = false;

    try {
      const response = await this.getActivity({
        tenantId: parms.tenantId,
        userId: parms.userId,
        fromDate: parms.fromDate,
        toDate: parms.toDate,
        status: parms.status,
        limit: parms.limit,
        exclusiveStartKey: parms.exclusiveStartKey,
      });
      if (response.status !== 200) throw 'Cannot load user activity';
      this.userActivity = response.data.data.results;

      if (isEmpty(this.userActivity)) {
        EventBus.$emit(EVENTS.SHOW_TOAST, 'User does not have any activity.!', TOAST.INFO);
        this.isEnd = true;
      }

      if (parms.isInitialLoad) {
        this.isEnd = true;
      } else {
        if (!response.data.data.exclusiveStartKey) {
          this.isEnd = true;
          if (parms.isNext) this.listExclusiveStartKey.push(this.lastExclusiveStartKey!);
        } else {
          this.isEnd = false;
          if (parms.isPrevious) this.lastExclusiveStartKey = parms.exclusiveStartKey!;
          else {
            this.listExclusiveStartKey.push(this.lastExclusiveStartKey!);
            this.lastExclusiveStartKey = this.currentExclusiveStartKey;
          }
          this.currentExclusiveStartKey = response.data.data.exclusiveStartKey;
        }
      }

      this.pageNumber = parms.pageNumber;
      if (this.pageNumber <= 1) this.lastExclusiveStartKey = null;
      if (parms.limit) {
        if (parms.isNext) this.slOffset += parms.limit;
        if (parms.isPrevious) this.slOffset -= parms.limit;
      }
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Cannot load user activity', TOAST.WARNING);
    } finally {
      this.isUserActivityLoading = false;
    }
  }

  async getActivity(params: Partial<GetActivityPayloadWithStatus>) {
    if (isEmpty(params.userId)) delete params.userId;
    if (isEmpty(params.fromDate)) delete params.fromDate;
    if (isEmpty(params.toDate)) delete params.toDate;
    if (isEmpty(params.status)) delete params.status;
    const serviceLinks = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
    const getUserDataUrl = serviceLinks.getUserActivity;
    const response = await post(getUserDataUrl, { data: params });

    return response;
  }
}
