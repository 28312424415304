

























































import { Mapping } from '@/common/xmpUtils';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MetadataPart from './MetadataPart.vue';
import * as metadataHandler from '@/common/metadataHandler';
import { getUpPageInitMdfTypes } from '@qmu/common/metaDataSetting/mimir';
import { GetMdfFileFieldsSchema, MappingDataSchema, GetMdfsAndMappingsSchema, GetMdfFileViewsItemSchema, GetMdfFileViewsUploadSchema } from '@qmu/common/dto/mimirDto';
import eventBus from '@/eventBus';
import { JsonData, MimirMdfCacheDto, MimirMetadataDto } from '@qmu/common/dto/MetadataModels';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { addDefaults, convertTomimirFform } from '@/utils/metadataUtils';
import { isEmpty } from 'lodash';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { post, put } from '@/common/rest';
import { ItemState, SingleUploadSignedUrlPayload, SingleUploadSignedUrlResponse } from '@qmu/common/dto/itemModels';
import { makeMetadataJsonFileName, sleep } from '@/utils/fileUtilities';
import { FileUpload } from '@/utils/Types/UploadTypes';

@Component({
  components: {
    MetadataPart,
  },
})
export default class MetadataComponent extends Vue {
  loadingFlag: boolean = false;
  xml: Document | null = null;
  fileContent: string = '';
  componentFlag: boolean = false;
  uploadflag: boolean = false;
  fetchedMetadata: string = 'ROH';
  @Prop() jsonifyMappingFlag!: boolean;

  get resetFlag() {
    return store.getters[GettersTypes.GET_METADATA_RESET_FLAG];
  }

  set resetFlag(value: boolean) {
    store.dispatch(ActionsTypes.SET_METADATA_RESET_FLAG);
  }

  get isFetchingMdfMap() {
    return isEmpty(this.mdf);
  }

  get mdf() {
    return (store.getters[GettersTypes.GET_MIMIR_MDF_CACHE] as MimirMdfCacheDto).mdf;
  }

  set mdf(value: GetMdfFileFieldsSchema[]) {
    store.dispatch(ActionsTypes.SET_MIMIR_CACHE_MDF, value);
  }

  get mappingData() {
    return (store.getters[GettersTypes.GET_MIMIR_MDF_CACHE] as MimirMdfCacheDto).mappingData;
  }

  set mappingData(value: MappingDataSchema) {
    store.dispatch(ActionsTypes.SET_MIMIR_CACHE_MAPPING_DATA, value);
  }

  get mdfItems() {
    return (store.getters[GettersTypes.GET_MIMIR_MDF_CACHE] as MimirMdfCacheDto).mdfItems;
  }

  set mdfItems(value: GetMdfFileViewsItemSchema[]) {
    store.dispatch(ActionsTypes.SET_MIMIR_CACHE_MDF_ITEMS, value);
  }

  get mdfUpload() {
    return (store.getters[GettersTypes.GET_MIMIR_MDF_CACHE] as MimirMdfCacheDto).mdfUpload;
  }

  set mdfUpload(value: GetMdfFileViewsUploadSchema[]) {
    store.dispatch(ActionsTypes.SET_MIMIR_CACHE_MDF_UPLOADS, value);
  }

  get mdfIdRoh() {
    return (store.getters[GettersTypes.GET_MIMIR_MDF_CACHE] as MimirMdfCacheDto).mdfIdRoh;
  }

  set mdfIdRoh(value: string) {
    store.dispatch(ActionsTypes.SET_MIMIR_CACHE_MDF_ID_ROH, value);
  }

  getLabelByFieldId(fieldId: string) {
    for (const item of this.mdfItems) {
      if (item.fieldId === fieldId) return item.label;
    }
  }

  getFieldNameById(id: string) {
    for (const item of this.mdf) {
      if (item.id === id) return item.fieldId;
    }
  }
  getVisibilityByid(id: string) {
    return this.mappingData[id] ? this.mappingData[id].visibility : true;
  }

  getDisabilityByid(id: string) {
    return this.mappingData[id] ? this.mappingData[id].readOnly : false;
  }

  get dataObject() {
    return store.getters[GettersTypes.GET_METADATA_FIELD_VALUES];
  }

  get metadataFieldStatus() {
    return store.getters[GettersTypes.GET_METADATA_FIELD_STATUS];
  }

  set metadataFieldStatus(value: boolean) {
    store.dispatch(ActionsTypes.UPDATE_METADATA_FIELD_STATUS, value);
  }

  get metadataXmpFlag() {
    return store.getters[GettersTypes.GET_METADATA_FROM_XMP_FLAG];
  }

  set metadataXmpFlag(value: boolean) {
    store.dispatch(ActionsTypes.SET_METADATA_XMP_FLAG, value);
  }

  get metadataHistoryFlag() {
    return store.getters[GettersTypes.GET_METADATA_HISTORY_FLAG];
  }

  set metadataHistoryFlag(value: boolean) {
    store.dispatch(ActionsTypes.SET_METADATA_HISTORY_FLAG, value);
  }

  async fetchRequiredMetadata(): Promise<GetMdfsAndMappingsSchema> {
    try {
      return await metadataHandler.fetchMdfsAndMapping(getUpPageInitMdfTypes());
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to load Metadata', TOAST.ERROR);
      return { mdfsFields: [], mdfsMappings: {}, mdfItems: [], mdfUpload: [] };
    }
  }

  @Watch('jsonifyMappingFlag')
  async onDataChanged() {
    this.jsonifyMappings();
  }

  async jsonifyMappings() {
    const form = this.$refs.form as HTMLFormElement;
    if (form && form.validate() && this.mdfIdRoh) {
      this.metadataFieldStatus = true;
      const jsonFormData: JsonData = {
        formData: {},
      };
      jsonFormData.formData = this.dataObject;
      const addedDefaults = addDefaults(jsonFormData); // might not needed in the future.
      const convertedData = convertTomimirFform(addedDefaults, this.mdfIdRoh);
      this.$emit('jsonFormData', convertedData);
      this.$emit('metadataAddFlag', true);
      eventBus.$emit(EVENTS.SHOW_TOAST, 'Metadata updated for uploading files', TOAST.SUCCESS);
      this.saveMetadataToS3(convertedData);
      return;
    }
    eventBus.$emit(EVENTS.SHOW_TOAST, 'Required fields are empty!', TOAST.ERROR);
  }

  getTextData(textData: Mapping) {
    this.dataObject[textData.id] = textData.value;
  }

  getChoiceData(choiceData: Mapping) {
    this.dataObject[choiceData.id] = choiceData.value;
  }

  getMultipleChoiceData(multipleChoiceData: Mapping) {
    this.dataObject[multipleChoiceData.id] = [...multipleChoiceData.value];
  }

  getDateData(dateData: Mapping) {
    this.dataObject[dateData.id] = dateData.value;
  }

  getCheckData(checkData: Mapping) {
    this.dataObject[checkData.id] = checkData.value;
  }

  get xmpEmail() {
    return store.getters[GettersTypes.GET_XMP_EMAIL];
  }

  set xmpEmail(value: string | null) {
    store.dispatch(ActionsTypes.SET_XMP_EMAIL, value);
  }

  async saveMetadataToS3(jsonData: MimirMetadataDto) {
    const serviceLinks = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
    const metadataInfo = this.dataObject;
    const title = metadataInfo['title'];
    let databaseId = '';
    try {
      const createMetadataResponse = await this.createMetadataEntry(title, serviceLinks.createMetadata);
      const { id, fileId } = createMetadataResponse.data.data;
      databaseId = id;
      await this.updateMetadataEntry(id, ItemState.PROGRESSING, serviceLinks.updateMetadata);
      await this.uploadMetadataToS3(fileId, jsonData);
      await this.updateMetadataEntry(id, ItemState.COMPLETE, serviceLinks.updateMetadata);
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Cannot save metadata', TOAST.ERROR);
      if (databaseId) this.updateMetadataEntry(databaseId, ItemState.ERROR, serviceLinks.updateMetadata);
    }
  }

  async createMetadataEntry(title: string, url: string) {
    const params = { itemState: ItemState.NEW, title: title };
    return await post(url, { data: params });
  }

  async updateMetadataEntry(id: string, itemState: string, url: string) {
    const params = { id: id, itemState: itemState };
    return await post(url, { data: params });
  }

  async uploadMetadataToS3(fileId: string, jsonData: MimirMetadataDto) {
    const fileName = makeMetadataJsonFileName(fileId);
    const parts = [JSON.stringify(jsonData)];

    const file = new File(parts, fileName, {
      lastModified: new Date().getUTCDate(),
      type: 'text/plain',
    });
    await this.uploadJsonFileToS3(file as FileUpload);
  }

  async uploadJsonFileToS3(file: FileUpload) {
    const payload: SingleUploadSignedUrlPayload = { fileName: file.name };
    while (true) {
      try {
        const url = store.getters[GettersTypes.GET_METADATA_ON_S3_LINK];
        const response = await post(url, { data: payload });
        const presignedUrl: SingleUploadSignedUrlResponse = response.data.data;
        await this.metaDataSingleUpload(presignedUrl.url, file);
        break;
      } catch (error) {
        if (error.response && error.response.status >= 500) {
          EventBus.$emit(EVENTS.SHOW_TOAST, 'Cannot save metadata try again!', TOAST.ERROR);
          break;
        }
      }
    }
  }

  async metaDataSingleUpload(signedUrl: string, file: FileUpload, waitBeforeExecutionInMillis = 500) {
    let tryNumber = 1;
    while (tryNumber) {
      try {
        await put(signedUrl, { data: file });
        break;
      } catch (error) {
        if (error.response && error.response.status >= 500) {
          throw error;
        }
        waitBeforeExecutionInMillis = tryNumber > 220 ? 60 * 1000 : tryNumber > 110 ? 30 * 1000 : waitBeforeExecutionInMillis * 2;
        tryNumber++;
        await sleep(waitBeforeExecutionInMillis);
      }
    }
  }
}
