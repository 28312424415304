const emailValidationRegx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/;

export const validEmailRules = [(v: string) => !!v || 'Email is required', (v: string) => !v || emailValidationRegx.test(v) || 'Email must be valid'];
export const validEmailsRules = [(v: string[]) => !v || isEmailsValid(v) || 'Email must be valid'];
export const validPerSessionUploadCountRules = [
  (v: string) => {
    if (v === '*') return true;
    try {
      const value = parseInt(v);
      return (value >= 1 && value <= 100) || 'Value exceeded';
    } catch (error) {
      return false;
    }
  },
];
const isEmailsValid = (emails: string[]) => !emails.some(item => emailValidationRegx.test(item) === false);
export const isEmailValid = (email: string) => emailValidationRegx.test(email);
