

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { changeDateFormatToEuropean, getTodaysDate } from '@qmu/common/util/formatData';
import { DateRangeType } from '@qmu/common/dto/dateRangeDto';

@Component
export default class FromToDate extends Vue {
  @Prop({ default: false }) isClear!: boolean;
  startDate: string | null = null;
  endDate: string | null = null;
  startDateMenu: boolean = false;
  endDateMenu: boolean = false;

  dateRules = [(v: string) => !!v || 'Date is required'];

  @Watch('isClear')
  onDataChanged(){
    (this.$refs.fromDateForm as HTMLFormElement).reset();
    (this.$refs.toDateForm as HTMLFormElement).reset();
    this.startDate = null;
    this.endDate = null;
  }

  get formattedEndDate() {
    return this.endDate ? changeDateFormatToEuropean(this.endDate) : '' ;
  }

  set formattedEndDate(date:string) {
    this.endDate = date;
  }

  get formattedStartDate() {
    return this.startDate ? changeDateFormatToEuropean(this.startDate) : '' ;
  }

  set formattedStartDate(date:string) {
    this.startDate = date ;
  }

  get getToday() {
    return getTodaysDate();
  }

  get getExtraDateRules() {
    return () => this.endDate! >= this.startDate! || 'end date must be greater or equal to start date';
  }

  changeFromDate(){
    this.endDate = this.startDate;
    this.changeFromToDate();
  }

  changeFromToDate(){
    const dateParams: DateRangeType = {
      fromDate: this.startDate ?? '',
      toDate: this.endDate ?? '',
    };
    this.$emit('triggerFromToDate', dateParams);
  }
}
