


























import { Component } from 'vue-property-decorator';
import { GroupHandlingRequest } from '@/dialog';
import { mixins } from 'vue-class-component';
import DialogMixin from '@/mixins/dialog-mixin';
import BaseDialog from './BaseDialog.vue';
import GroupHandling from '@/admin/components/GroupHandling.vue'
@Component({
  components: {
    BaseDialog,
    GroupHandling,
  }
})
export default class GroupHandlingDialog extends mixins<DialogMixin<GroupHandlingRequest>>(DialogMixin) {
  loading = false;
  applyClicked = false;
  header = `Edit user group of (Email: ${this.req.userDataModel.email})`;
  /**
   * Sending update to child
   */
  actionClicked(){
    this.applyClicked = !this.applyClicked;
  }

  cancelClicked(){
    // DO something on cancel clicked
  }
}
