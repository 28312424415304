import { isEmpty } from 'lodash';
import { UserType } from '../enum/UserTypes';

export const findUserGroup = (groupList: string[] | null): string => {
  if (!groupList || isEmpty(groupList)) return UserType.READONLY_USER;
  if (groupList.includes(UserType.SUPER_ADMIN)) return UserType.SUPER_ADMIN;
  if (groupList.includes(UserType.TENANT_ADMIN)) return UserType.TENANT_ADMIN;
  if (groupList.includes(UserType.NORMAL_USER)) return UserType.NORMAL_USER;
  return UserType.READONLY_USER;
};
