import { Auth } from 'aws-amplify';
import { RequestSender, DefaultHeadersType, GetRequestConfigType, PostRequestConfigType, PatchRequestConfigType, PutRequestConfigType, DeleteRequestConfigType } from '@qmu/common/apis/api';

async function getCurrentJwtToken(): Promise<DefaultHeadersType> {
  const {
    signInUserSession: {
      idToken: { jwtToken },
    },
  } = await Auth.currentAuthenticatedUser();

  return {
    'qmu-access-token': jwtToken,
  };
}

const requestSender = new RequestSender(getCurrentJwtToken);

// kind of defeats the purpose of RequestSender Class
// method is omitted due to support existing codes

// added default empty object because get request might have nothing except the url
export const get = async (url: string, requestConfig: Omit<GetRequestConfigType, 'method'> = {}) => requestSender.get(url, { ...requestConfig, method: 'GET' });
export const post = async (url: string, requestConfig: Omit<PostRequestConfigType, 'method'>) => requestSender.post(url, { ...requestConfig, method: 'POST' });

export const put = async (url: string, requestConfig: Omit<PutRequestConfigType, 'method'>) => requestSender.put(url, { ...requestConfig, method: 'PUT' });
export const patch = async (url: string, requestConfig: Omit<PatchRequestConfigType, 'method'>) => requestSender.patch(url, { ...requestConfig, method: 'PATCH' });
// delete is a keyword, hence naming a delete i not allowed
// added default empty object because delete request might have nothing except the url
export const DELETE = async (url: string, requestConfig: Omit<DeleteRequestConfigType, 'method'> = {}) => requestSender.delete(url, { ...requestConfig, method: 'DELETE' });
