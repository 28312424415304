import axios, { AxiosRequestConfig } from 'axios';
import { AxiosResponse } from 'axios';

/*
Created this type because request will be sent using a separate parameter, there's no use in giving option to pass url multiple time
cancelToken IS DEPRECATED, Hence omitting it.
*/
export type RequestWithoutURL = Omit<AxiosRequestConfig, 'url' | 'baseURL' | 'cancelToken'>;

export type GetRequestConfigType = Pick<RequestWithoutURL, 'headers' | 'onDownloadProgress' | 'signal' | 'params'> & { method: 'GET' };
export type PostRequestConfigType = Pick<RequestWithoutURL, 'headers' | 'data' | 'onUploadProgress' | 'signal' | 'params'> & { method: 'POST' };

// Derived From Post
export type PutRequestConfigType = { method: 'PUT' } & Omit<PostRequestConfigType, 'method'>;
export type PatchRequestConfigType = { method: 'PATCH' } & Omit<PostRequestConfigType, 'method'>;

// Derived From Get
export type DeleteRequestConfigType = { method: 'DELETE' } & Omit<GetRequestConfigType, 'method' | 'onDownloadProgress'>;

export type DefaultHeadersType = {
  'qmu-access-token': string;
};

interface RequestInterface {
  get(url: string, requestConfig?: RequestWithoutURL): Promise<AxiosResponse>;
  post(url: string, requestConfig: RequestWithoutURL): Promise<AxiosResponse>;
  put(url: string, requestConfig: RequestWithoutURL): Promise<AxiosResponse>;
  patch(url: string, requestConfig: RequestWithoutURL): Promise<AxiosResponse>;
  delete(url: string, requestConfig?: RequestWithoutURL): Promise<AxiosResponse>;
}

export class RequestSender implements RequestInterface {
  defaultHeaderGenerator: () => Promise<DefaultHeadersType>;

  // typescript is not giving error on assigning a function with the wrong return type // will check later
  constructor(defaultHeaderGenerator: () => Promise<DefaultHeadersType>) {
    this.defaultHeaderGenerator = defaultHeaderGenerator;
  }

  // not in interface // need to rethink this
  private async sendRequest(url: string, requestConfig: RequestWithoutURL, defaultHeaders: () => Promise<DefaultHeadersType> = this.defaultHeaderGenerator): Promise<AxiosResponse> {
    if (!requestConfig.headers) requestConfig.headers = {};
    // adding the default headers with user headers if any
    Object.assign(requestConfig.headers, await defaultHeaders());
    return axios({
      url,
      ...requestConfig,
    });
  }
  public async get(url: string, requestConfig: GetRequestConfigType = { method: 'GET' }) {
    return this.sendRequest(url, requestConfig);
  }

  public async post(url: string, requestConfig: PostRequestConfigType) {
    return this.sendRequest(url, requestConfig);
  }
  public async put(url: string, requestConfig: PutRequestConfigType) {
    return this.sendRequest(url, requestConfig);
  }

  public async patch(url: string, requestConfig: PatchRequestConfigType) {
    return this.sendRequest(url, requestConfig);
  }

  public async delete(url: string, requestConfig: DeleteRequestConfigType = { method: 'DELETE' }) {
    return this.sendRequest(url, requestConfig);
  }
}
