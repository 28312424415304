




























































import store, { GettersTypes } from '@/store';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { Component, Vue } from 'vue-property-decorator';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import { GetMdfFileFieldsSchema, MdfsItemInfo, MappingDataSchema } from '@qmu/common/dto/mimirDto';
import { getSupportedMdfTypes } from '@qmu/common/metaDataSetting/mimir';
import * as metaDataHandler from '@/common/metadataHandler';
import { MimirMdfCacheDto } from '@qmu/common/dto/MetadataModels';

@Component
export default class MetaData extends Vue {
  mdfData: Array<GetMdfFileFieldsSchema> = [];
  mappingData: MappingDataSchema = {};
  editFlag: boolean = false;
  selectedMdf: MdfsItemInfo | null = null;
  xmpItems: Array<MdfsItemInfo> = [];
  loadingFlag: boolean = false;
  serviceLinks = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  suppportedMdfTypes = getSupportedMdfTypes();
  loadingMdfNames: boolean = false;
  isPopulated = false;

  get mdf() {
    return (store.getters[GettersTypes.GET_MIMIR_MDF_CACHE] as MimirMdfCacheDto).mdf;
  }

  async created() {
    this.loadingMdfNames = true;
    this.xmpItems = await metaDataHandler.fetchMdfsInfo();
    this.loadingMdfNames = false;
  }

  async tryCreateingMap(mdfType: string, mdfFields: Array<GetMdfFileFieldsSchema>) {
    if (await metaDataHandler.createMapping(mdfType, mdfFields)) {
      try {
        this.mappingData = await metaDataHandler.fetchMapping(mdfType);
      } catch (err) {
        EventBus.$emit(EVENTS.SHOW_TOAST, 'Mapping fetching Failed', TOAST.WARNING);
      }
    }
  }

  async populateSelectedMdfMap(): Promise<void> {
    if (!this.selectedMdf) return;
    this.loadingFlag = true;
    this.isPopulated = true;
    const mdfFields: Array<GetMdfFileFieldsSchema> = await metaDataHandler.fetchMDFFields(this.selectedMdf.id);
    if (mdfFields.length > 0) {
      this.mdfData = mdfFields;
      try {
        this.mappingData = await metaDataHandler.fetchMapping(this.selectedMdf.label);
        this.mdfData.forEach(data => {
          if(this.mappingData[data.id] && !this.mappingData[data.id].hasOwnProperty('required')){
            this.mappingData[data.id].required = data.required;
          }
          if (!this.mappingData[data.id])
            this.mappingData[data.id] = {
              xmpFieldName: '',
              readOnly: false,
              visibility: true,
              defaultDateFlag: false,
              required: false,
            };
        });
      } catch (error) {
        if (error.response && error.response.status === 404 && this.selectedMdf) {
          await this.tryCreateingMap(this.selectedMdf.label, mdfFields);
        } else {
          EventBus.$emit(EVENTS.SHOW_TOAST, 'Mapping fetching Failed', TOAST.WARNING);
        }
      }
    }
    this.loadingFlag = false;
  }

  clear(fieldId: string): void {
    EventBus.$emit(EVENTS.SHOW_TOAST, `You have cleared ${fieldId}`, TOAST.WARNING);
  }

  toggleEditStatus(): void {
    this.editFlag = !this.editFlag;
    if (this.editFlag) EventBus.$emit(EVENTS.SHOW_TOAST, `Editing is Enabled`, TOAST.DEFAULT);
    else EventBus.$emit(EVENTS.SHOW_TOAST, `Editing is disabled`, TOAST.WARNING);
  }

  async updateMappings(selectedMdf: MdfsItemInfo): Promise<void> {
    await metaDataHandler.updateMappings(selectedMdf.label, this.mappingData); //errors are emited internally
    // Modifying the mdfsFields using the mapping data for required fields on update
    this.mdf.forEach(data => {
      if(this.mappingData[data.id]){
        data.required = this.mappingData[data.id].required;
      }
    });
    this.editFlag = false;
  }
}
