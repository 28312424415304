





import { Component, Vue, Watch } from 'vue-property-decorator';
import { UserDataModel, UserPoolData } from '@/common/userPoolData';
import LoadUser from './LoadUser.vue';
import { SingleUserDataResponse } from '@qmu/common/dto/userDataDto';
import { UserOperationType } from '@/common/enums/userOperationEnums';
import { LoadUserEmitDto } from '@/admin/dto/loadUserDto';
import EventBus, { EVENTS, TOAST } from '@/eventBus';

@Component({
  components: {
    LoadUser,
  },
})
export default class UserGroups extends Vue {
  selectedUserData: SingleUserDataResponse | null = null;
  userDataModel: UserDataModel | null = null;
  deletedUserName = '';

  get reactiveDeletedUserName() {
    return this.deletedUserName;
  }

  set reactiveDeletedUserName(value: string) {
    this.deletedUserName = value;
  }

  @Watch('userDataModel')
  onDataChanged() {
    if (this.selectedUserData?.groups) this.selectedUserData.groups = this.userDataModel?.groups;
  }

  getUserInfo(event: LoadUserEmitDto) {
    this.selectedUserData = event.userData;
    this.userDataModel = {
      username: event.userData.userName ?? '',
      enabled: event.userData.emailVerified ?? '',
      userCreatedAt: event.userData.createdOn ?? '',
      userStatus: event.userData.cognitoUserStatus ?? '',
      emailVerified: event.userData.emailVerified ?? '',
      email: event.userData.email ?? '',
      groups: event.userData.groups ?? [],
      userId: event.userData.id ?? '',
      tenantId: event.tenantId ?? '',
    };
    if (event.operationType === UserOperationType.UPDATE_GROUP) this.$dialog.showGroupHandlingDialog(this.userDataModel);
    if (event.operationType === UserOperationType.DELETE_USER)
      this.$dialog.showSimpleDialog(
        {
          closeOnActionClick: true,
          icon: 'mdi-delete-alert-outline',
          maxWidth: 400,
          header: `Deleting (${this.userDataModel.email})`,
          baseHtml: 'User account will be deleted and current upload progress for this user will be stopped',
        },
        this.deleteUser
      );
  }

  /**
   * Delete users present in current object userDataModel
   * Show pleaseWait dialog while deleting and show proper
   * message on success/failed and update UI with the changes.
   */
  async deleteUser() {
    if (!(this.userDataModel?.username && this.userDataModel.userId && this.userDataModel.tenantId)) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Something is wrong. Please reload your browser', TOAST.ERROR);
      return;
    }
    this.$dialog.showPleaseWaitDialog('Deleting user in progress');
    if (await new UserPoolData().deleteUserAccountWithData(this.userDataModel.username, this.userDataModel.tenantId, this.userDataModel.userId)) {
      this.reactiveDeletedUserName = this.userDataModel.username;
      EventBus.$emit(EVENTS.SHOW_TOAST, 'User deleted Successfuly', TOAST.SUCCESS);
    } else EventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to delete user', TOAST.ERROR);

    this.$dialog.hidePleaseWaitDialog();
  }
}
