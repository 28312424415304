import Vue, { CreateElement } from 'vue';
import App from './App.vue';
import store, { ActionsTypes, GettersTypes } from './store';
import vuetify from './plugins/vuetify';
import router from './router';
import { bootStrapper } from './bootstrapClient';
import { dialog } from './dialog';
import { UserType } from '@qmu/common/enum/UserTypes';
import { protectedPathForGroups, getListOfPaths } from '@/router/routeData';
import { clearAllActivityUpdateRepeatInterval } from './utils/fileUtilities';

Vue.config.productionTip = false;
Vue.prototype.$dialog = dialog;

const continueIfAllowed = (path: string): void => {
  if (protectedPathForGroups[store.getters[GettersTypes.GET_USER_GROUP] as UserType].includes(path)) return;
  throw new Error('Path restricted');
};

// Allows user in routes
router.beforeResolve(async (to, from, next): Promise<void> => {
  try {
    const isInitialLoad = store.getters[GettersTypes.GET_INITIAL_LOAD];
    if (!isInitialLoad) bootStrapper();
    clearAllActivityUpdateRepeatInterval();
    continueIfAllowed(to.path);
    store.dispatch(ActionsTypes.SET_APP_BAR_SELECTED_INDEX, getListOfPaths().indexOf(to.path));
    next();
  } catch (error) {
    if (to.path === '/') next();
    else next('/');
  }
});

// update title
const DEFAULT_TITLE = 'Qbics Upload Tool';
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to?.meta?.title || DEFAULT_TITLE;
  });
});

new Vue({
  vuetify,
  el: '#app',
  store,
  router,
  render(h: CreateElement) {
    return h(App);
  },
}).$mount('#app');
