import SimpleDialog from './SimpleDialog.vue';
import PleaseWaitDialog from './PleaseWaitDialog.vue';
import GroupHandlingDialog from './GroupHandlingDialog.vue';
import CopyTextDialog from './CopyTextDialog.vue';
import { DialogType } from '@/dialog';
import { VueConstructor } from 'vue';

export type DialogComponents = { [D in DialogType]: VueConstructor };

export const components: DialogComponents = {
  [DialogType.SIMPLE]: SimpleDialog,
  [DialogType.WAIT]: PleaseWaitDialog,
  [DialogType.COPY_TEXT]: CopyTextDialog,
  [DialogType.GROUP_HANDLING]: GroupHandlingDialog,
};
