


































import { Component, Vue } from 'vue-property-decorator';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import { DateRangeType } from '@qmu/common/dto/dateRangeDto';
import { changeDateFormatToEuropean, getTodaysDate } from '@qmu/common/util/formatData';

@Component
export default class DateRange extends Vue {
  startDate: string = this.getToday;
  endDate: string = this.getToday;
  startDateMenu: boolean = false;
  endDateMenu: boolean = false;

  // rules
  dateRules = [(v: string) => !!v || 'Date is required'];

  async loadActivityWithDateRange() {
    const dateCompare = Date.parse(this.endDate) >= Date.parse(this.startDate);
    const dateFormValidation = (this.$refs.dateRangeForm as Vue & { validate: () => boolean }).validate();
    if (!dateCompare && !dateFormValidation) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Error! fix start & end date', TOAST.WARNING);
      return;
    }
    const dateParams: DateRangeType = {
      fromDate: this.startDate,
      toDate: this.endDate,
    };
    this.$emit('triggerDateRange', dateParams);
  }

  get formattedEndDate() {
    return this.endDate ? changeDateFormatToEuropean(this.endDate) : '' ;
  }

  get formattedStartDate() {
    return this.startDate ? changeDateFormatToEuropean(this.startDate) : '' ;
  }

  get getToday() {
    return getTodaysDate();
  }

  get getExtraDateRules() {
    return () => this.endDate >= this.startDate || 'end date must be greater or equal to start date';
  }

  async clearDate() {
    this.startDate = '';
    this.endDate = '';
    this.$emit('triggerClear');
  }
}
