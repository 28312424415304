













import { Component } from 'vue-property-decorator';
import { SimpleDialogRequest } from '@/dialog';
import { mixins } from 'vue-class-component';
import DialogMixin from '@/mixins/dialog-mixin';
import BaseDialog from './BaseDialog.vue';

@Component({
  components: {
    BaseDialog
  }
})
export default class SimpleDialog extends mixins<DialogMixin<SimpleDialogRequest>>(DialogMixin) {

  onOk() {
    this.req.callback('ok');
  }

  onSecondaryOk() {
    this.req.callback('secondaryOk');
  }

  onCancel() {
    this.req.callback('cancel');
    this.showDialog = false;
  }
}
