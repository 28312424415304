const supportedMdfType: Array<string> = ['common', 'roh']; // everything here must be in lowercase
const uploadPageInitialMdfs: Array<string> = ['common', 'roh']; // everything here must be in lowercase

export const getSupportedMdfTypes = () => supportedMdfType;

export function getUpPageInitMdfTypes(): Array<string> {
  const resp = uploadPageInitialMdfs.filter(mdfType => supportedMdfType.includes(mdfType));
  if (resp && resp.length > 0) return resp;
  throw new Error('Failed to fetch Upload page initial mdf types');
}
