import { ClientConfig } from '../dto/ClientConfigDto';
/**
 * Credentials Interface for Cognito authentication
 */
interface CognitoConfig {
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  aws_cognito_identity_pool_id: string;
  aws_mandatory_sign_in: string;
  identityPoolId: string;
  region: string;
  userPoolId: string;
  oauth: {
    domain: string;
    scope: Array<string>;
    redirectSignIn: string;
    redirectSignOut: string;
    responseType: string;
  };
}

/**
 * Generate a awsconfig object based on ClinetConfig from service document.
 * @param clientConfig
 * @returns CognitoConfig which can be directly used in Auth.config(CognitoConfig)
 */
export function getCognitoConfig(clientConfig: ClientConfig): CognitoConfig {
  const config: CognitoConfig = {
    aws_cognito_region: clientConfig.cognitoRegion,
    aws_user_pools_id: clientConfig.userPoolId,
    aws_user_pools_web_client_id: clientConfig.appClientId,
    aws_cognito_identity_pool_id: clientConfig.identityPoolId,
    aws_mandatory_sign_in: clientConfig.cognitoMandatorySignIn,
    identityPoolId: clientConfig.identityPoolId,
    region: clientConfig.apiRegion,
    userPoolId: clientConfig.userPoolId,
    oauth: {
      domain: clientConfig.oauthDomain,
      scope: clientConfig.oauthScopes.split(' '),
      redirectSignIn: `${window.location.protocol}//${window.location.host}`,
      redirectSignOut: `${window.location.protocol}//${window.location.host}`,
      responseType: clientConfig.oauthResponseType,
    },
  };
  return config;
}
