


































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class DialogActions extends Vue {
  @Prop({default: 'Cancel'}) cancelText!: string;
  @Prop({default: 'Ok'}) actionText!: string;
  @Prop({default: false}) disableAction!: boolean;
  @Prop({default: false}) disableCancel!: boolean;
  @Prop({default: false}) loading!: boolean;
  @Prop({default: false}) disableSecondaryAction!: boolean;
  @Prop() secondaryActionText?: string;
  @Prop() infoOnly?: boolean;
  @Prop() disabledTooltip?: string;
}
