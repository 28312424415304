
















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import EventBus, { EVENTS, TOAST } from '@/eventBus';

@Component({name: 'copy-button'})
export default class CopyButton extends Vue {
  @Prop() content!: any;
  @Prop({default: undefined }) btnText?: string;
  @Prop({default: 'Copied to clipboard.'}) snackBarText!: string;
  @Prop({default: 'content_copy'}) icon!: string;
  @Prop() tooltip?: string;
  @Prop({default: true}) tooltipTop!: boolean;
  @Prop({default: false}) smallIcon!: boolean;
  @Prop({default: 'white'}) iconColor!: string;

  // Set to true to show an inline 'Copied!' text next to the clip board button. There may not be space for this,
  // so the default is to show a Toast info alert.
  @Prop({default: false}) useCopiedText !: boolean;

  showCopiedIcon = false;

  sendNotification() {

    if (this.useCopiedText) {
      this.showCopiedIcon = true;
      setTimeout(() => {
        this.showCopiedIcon = false;
      }, 2000);
    } else {
      EventBus.$emit(EVENTS.SHOW_TOAST, this.snackBarText, TOAST.INFO);
    }
  }

  get iconProxy() {
    return this.showCopiedIcon ? 'check' : this.icon;
  }
}
