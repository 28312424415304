import moment from 'moment';

export const changeDateTimeFormatToEuropean = (value: string) => {
  return moment(String(value)).format('DD.MM.YYYY - HH:mm:ss');
};

export const changeDateFormatToEuropean = (value: string) => {
  return moment(String(value)).format('DD.MM.YYYY');
};

export const getTodaysDate = (): string => {
  return moment().format('YYYY-MM-DD');
};

export const getNowTime24H = (): string => {
  return moment().format('HH:mm');
};

export const getTodaysEuropeanDate = (): string => {
  return moment().format('DD.MM.YYYY');
};

export const getDateFormatFromISODateTime = (value: string): string => {
  return moment(value).format('YYYY-MM-DD');
};

export const getTimeFormatFromISODateTime = (value: string): string => {
  return moment(value).format('HH:mm');
};
