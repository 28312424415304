














import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ClearFilter extends Vue {
  clearFilter() {
    this.$emit('triggerClearFilter');
  }
}
