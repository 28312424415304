import { VuetifyThemeVariant } from 'vuetify/types/services/theme';

const current: Partial<VuetifyThemeVariant> = {
  name: 'light',
  bgColor: '#F2F2F2',
  bgSecondary: '#e4e4e4',
  progressBarBgColor: '#CFD8DC',
  progressBarFileNameColor: '#C8E6C9',
  progressBarFileSizeColor: '#C5CAE9',
  progressBarSpeedometerColor: '#BBDEFB',
  scrollBarBgColor: '#F2F2F2',
  scrollBarTrackBgColor: '#9f9f9f',
  scrollbarThumbColor: '#9f9f9f',
  scrollbarThumbHoverColor: '#555555',
  progressBarRemainingTimeColor: '#C8E6C9',
  dropzoneBackground: '#e1eef2',
  buttonPrimary: '#3A85C9',
  buttonSecondary: '#7A8792',
  buttonSuccess: '#57C04B',
  textFieldPrimary: '#fff',
  tablePrimary: '#fff',
  loginPageInputText: '#485c6b',
  vMenuBgColor: '#fff',
  cardLoadingColor: '#0c71cd',
  tableHeaderBgColor: '#fff',
  tableRowBgColor: '#fff',
  progressError: '#ef5350',
  progressPrimary: '#3A85C9',
  progressWarning: '#E9A642',
  progressSuccess: '#57C04B',
  dateTimePickerSelectColor: '#3A85C9',
  timePickerTitleColor: '#000000',
};

const redesign: Partial<VuetifyThemeVariant> = {
  name: 'dark',
  bgColor: '#252D34',
  bgSecondary: '#374148',
  progressBarBgColor: '#33383b',
  progressBarFileNameColor: '#142e15',
  progressBarFileSizeColor: '#1e244a',
  progressBarSpeedometerColor: '#182938',
  scrollBarBgColor: '#252D34',
  scrollBarTrackBgColor: '#405361',
  scrollbarThumbColor: '#405361',
  scrollbarThumbHoverColor: '#628097',
  progressBarRemainingTimeColor: '#142e15',
  dropzoneBackground: '#394042',
  buttonPrimary: '#3A85C9',
  buttonSecondary: '#7A8792',
  buttonSuccess: '#57C04B',
  textFieldPrimary: '#2f383e',
  tablePrimary: '#2f383e',
  loginPageInputText: '#485c6b',
  vMenuBgColor: '#2f383e',
  cardLoadingColor: '#3A85C9',
  tableHeaderBgColor: '#2f383e',
  tableRowBgColor: '#2f383e',
  progressError: '#ef5350',
  progressPrimary: '#3A85C9',
  progressWarning: '#E9A642',
  progressSuccess: '#57C04B',
  dateTimePickerSelectColor: '#3A85C9',
  timePickerTitleColor: '#ffffff',
};

export default redesign;

export const themes: Partial<VuetifyThemeVariant>[] = [current, redesign];
