









import { toggleSubscriptionStatus } from '@/common/notificationServices';
import eventBus, { EVENTS, TOAST } from '@/eventBus';
import NotificationType from '@qmu/common/enum/NotificationType';
import { Component, Vue, Prop } from 'vue-property-decorator';
import store from '@/store';
import { GettersTypes } from '@/store';

@Component
export default class NotificationSubscription extends Vue {
  @Prop({ type: String, required: true }) switchLabel!: string;
  @Prop({ type: Boolean, required: true }) enableTogglingSubscription!: Boolean;

  subscriptionStatus: boolean = store.getters[GettersTypes.GET_SUBSCRIPTION_INFO];
  loading: boolean = false;
  tenantId: string = store.getters[GettersTypes.GET_TENANT_ID];
  userId: string = store.getters[GettersTypes.GET_USER_ID];
  address: string = store.getters[GettersTypes.GET_USER_EMAIL];
  currentSubscriptionStatus: boolean = this.subscriptionStatus;

  async toggleSubscription() {
    try {
      this.loading = true;
      const toggleSubscriptionData = await toggleSubscriptionStatus(this.address, NotificationType.EMAIL, this.subscriptionStatus);

      if (toggleSubscriptionData?.status !== 200) throw 'Unable to change the subscription status!';
      this.currentSubscriptionStatus = this.subscriptionStatus;
      const toastType = this.currentSubscriptionStatus ? TOAST.SUCCESS : TOAST.WARNING;
      const message = this.currentSubscriptionStatus ? 'Email Subscription Turned ON.' : 'Email Subscription Turned OFF';
      eventBus.$emit(EVENTS.SHOW_TOAST, message, toastType, 2000); //2sec
      this.$emit('setSubscription', this.subscriptionStatus);
    } catch (error) {
      this.subscriptionStatus = this.currentSubscriptionStatus;
      eventBus.$emit(EVENTS.SHOW_TOAST, error as string, TOAST.ERROR);
    } finally {
      this.loading = false;
    }
  }
}
