


















import { Mapping, MdfType } from '@/common/xmpUtils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import TextComponent from './MetadataFields/TextComponent.vue';
import ChoiceComponent from './MetadataFields/ChoiceComponent.vue';
import MultiplechoiceComponent from './MetadataFields/MultipleChoiceComponent.vue';
import DateComponent from './MetadataFields/DateComponent.vue';
import CheckBoxComponent from './MetadataFields/CheckBoxComponent.vue';
import store, { GettersTypes } from '@/store';

@Component({
  components: {
    TextComponent,
    ChoiceComponent,
    MultiplechoiceComponent,
    DateComponent,
    CheckBoxComponent,
  },
})
export default class MetadataComponent extends Vue {
  @Prop() readonly mdf!: MdfType;
  @Prop() readonly itemLabel!: string;
  @Prop() readonly visible!: boolean;
  @Prop() readonly disableFlag!: boolean;

  get dataObject() {
    return store.getters[GettersTypes.GET_METADATA_FIELD_VALUES];
  }

  getTextData(textData: Mapping) {
    this.$emit('textData', textData);
  }

  getChoiceData(choiceData: Mapping) {
    this.$emit('choiceData', choiceData);
  }

  getMultipleChoiceData(multipleChoiceData: Mapping) {
    this.$emit('multipleChoiceData', multipleChoiceData);
  }

  getDateData(dateData: Mapping) {
    this.$emit('dateData', dateData);
  }

  getCheckData(checkData: Mapping) {
    this.$emit('checkData', checkData);
  }
}
