var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","color":"bgSecondary","outlined":"","loading":_vm.isFileActivityLoading? 'cardLoadingColor': 'transparent'}},[_c('v-data-table',{staticClass:"custom-table-color",attrs:{"dense":"","headers":_vm.dataTableHeader,"items":_vm.fileActivity,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([(_vm.userGroup !== _vm.userType.NORMAL_USER)?{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.userEmail(item.createdBy)))]}}:null,{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.truncateMiddleOfText(_vm.getTitleName(item.title, item.fileId))))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTitleName(item.title, item.fileId)))])])]}},{key:"item.fileTrackers.realPercentage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fileTrackers.realPercentage.toFixed(2))+" %")]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.changeDateFormat(item.createdOn)))]}},{key:"item.modifiedOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.itemState === _vm.itemStateType.COMPLETE ? _vm.changeDateFormat(item.modifiedOn) : '-'))]}}],null,true)}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),[_c('v-btn',{attrs:{"icon":"","disabled":_vm.pageNumber <= 1 || _vm.isFileActivityLoading},on:{"click":_vm.previousPage}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_vm._v(" page: "+_vm._s(_vm.pageNumber)+" "),_c('v-btn',{attrs:{"icon":"","disabled":_vm.isEnd || _vm.isFileActivityLoading},on:{"click":_vm.nextPage}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }