

































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import { ActivityFilteringPayload } from '@qmu/common/dto/activityModels';
import { ExclusiveStartKeyType } from '@qmu/common/dto/genericDto';
import { post } from '@/common/rest';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { DateRangeType } from '@qmu/common/dto/dateRangeDto';
import { UserType } from '@qmu/common/enum/UserTypes';
import { UserInfo } from '@qmu/common/dto/UserInfoDto';
import { HandleLoadFileActivityPayload } from '@/admin/dto/handleLoadActivityDto';
import DateRange from '@/admin/components/DateRange.vue';
import { changeDateTimeFormatToEuropean } from '@qmu/common/util/formatData';
import { splitNameAndExtension } from '@qmu/common/util/general';
import { clearAllActivityUpdateRepeatInterval, getTruncateMiddleOfText } from '@/utils/fileUtilities';
import { getUserEmailFromUserId } from '@/utils/mappingUtilities';

import { GetFileActivityPayload, ItemState } from '@qmu/common/dto/itemModels';
import { isEmpty } from 'lodash';
import { getFileActivityDataTableHeaders } from '@/common/dataTableHeaders';
import { DashboardTabs } from '@/utils/enum/dashboard';
import { routePathMaps } from '@/router/routeData';

@Component({
  components: {
    DateRange,
  },
})
export default class FileHistory extends Vue {
  @Prop() readonly receivedPayload!: ActivityFilteringPayload;
  @Prop({ default: false }) viewAllFile!: Boolean;
  @Prop({ default: '' }) currentTab!: string;
  fileActivity: object[] = [];
  isFileActivityLoading = false;
  viewFileActivity = false;
  tenantId = '';
  userId = '';
  isEnd = true;
  pageNumber = 0;
  slOffset = 0;
  currentFromDate: string = '';
  currentToDate: string = '';
  currentExclusiveStartKey: ExclusiveStartKeyType | null = null;
  lastExclusiveStartKey: ExclusiveStartKeyType | null = null;
  listExclusiveStartKey: ExclusiveStartKeyType[] = [];
  itemState = '';
  title = '';
  interval: NodeJS.Timeout | null = null;
  repeatExclusiveStartKey: ExclusiveStartKeyType | null = null;

  async created() {
    this.viewFileActivity = false;
    this.isEnd = true;
    this.pageNumber = 0;
    this.slOffset = 0;
    this.listExclusiveStartKey = [];
  }

  get defaultLimit() {
    return store.getters[GettersTypes.GET_TENANT_CONFIG_MAX_HISTORY];
  }

  userEmail(userId: string) {
    return getUserEmailFromUserId(this.tenantId, userId);
  }

  @Watch('receivedPayload')
  onDataChanged() {
    this.getFileActivity(this.receivedPayload);
  }

  @Watch('currentTab')
  onDataChangedCurrentTab() {
    this.clearRepeat();
    if (this.currentTab === this.dashboardTabs.USER_ACTIVITY) this.repeat();
  }

  get dashboardTabs() {
    return DashboardTabs;
  }

  get dataTableHeader() {
    return getFileActivityDataTableHeaders(this.viewAllFile);
  }

  truncateMiddleOfText(title: string) {
    return getTruncateMiddleOfText(title);
  }

  getTitleName(title: string, fileId: string) {
    const { name, extension } = splitNameAndExtension(title);
    if (extension === 'json') return title;
    return `${name}_${fileId}.${extension}`;
  }

  async repeat() {
    this.clearRepeat();
    clearAllActivityUpdateRepeatInterval();

    /**
     * Skip unexpected realtime update on first load.
     * Before that repeat was running in backgroud on first app load tab change
     */
    if (this.currentTab !== this.dashboardTabs.USER_ACTIVITY) return;

    this.interval = setInterval(async () => {
      const repeatItemSate: string[] = [ItemState.COMPLETE, ItemState.ERROR, ItemState.CANCELLED];
      if (repeatItemSate.includes(this.itemState) || this.$route.path !== routePathMaps.dashBoard.path) {
        this.clearRepeat();
        clearAllActivityUpdateRepeatInterval();
        return;
      }
      try {
        const response = await this.getActivity({
          tenantId: this.tenantId,
          userId: this.userId,
          title: this.title,
          fromDate: this.currentFromDate,
          toDate: this.currentToDate,
          limit: this.defaultLimit,
          exclusiveStartKey: this.repeatExclusiveStartKey!,
          itemState: this.itemState,
        });
        if (response.status !== 200) throw 'Cannot load user file history';
        this.fileActivity = response.data.data.results;
        if (this.pageNumber <= 1) {
          this.lastExclusiveStartKey = null;
          this.currentExclusiveStartKey = response.data.data.exclusiveStartKey;
          this.listExclusiveStartKey.length = 0;
          this.listExclusiveStartKey.push(this.lastExclusiveStartKey!);
        }
      } catch (error) {
        EventBus.$emit(EVENTS.SHOW_TOAST, 'Cannot load user file history', TOAST.WARNING);
        this.clearRepeat();
      }
    }, 8000); //8 seconds

    await store.dispatch(ActionsTypes.ADD_ACTIVITY_UPDATE_TIME_INTERVAL, this.interval);
  }

  beforeDestroy() {
    this.clearRepeat();
    clearAllActivityUpdateRepeatInterval();
  }

  clearRepeat() {
    if (this.interval) clearInterval(this.interval);
  }

  changeDateFormat(value: string) {
    return changeDateTimeFormatToEuropean(value);
  }

  get userType() {
    return UserType;
  }

  get itemStateType() {
    return ItemState;
  }

  get userGroup() {
    return store.getters[GettersTypes.GET_USER_GROUP] as UserInfo;
  }

  async getFileActivity(dataParams: ActivityFilteringPayload) {
    this.tenantId = dataParams.tenantId;
    this.userId = dataParams.userId || '';
    this.itemState = dataParams.itemState || '';
    this.title = dataParams.title || '';
    this.pageNumber = 0;
    this.slOffset = 0;
    if (dataParams.fromDate && dataParams.toDate) this.loadFileActivityWithDateRange({ fromDate: dataParams.fromDate, toDate: dataParams.toDate });
    else {
      await this.handleLoadActivity({
        pageNumber: 1,
        isInitialLoad: false,
        isPrevious: false,
        isNext: false,
        tenantId: this.tenantId,
        userId: this.userId,
        title: this.title,
        limit: this.defaultLimit,
        itemState: this.itemState,
      });
    }
  }

  async loadFileActivityWithDateRange(dateRange: DateRangeType) {
    this.currentFromDate = dateRange.fromDate;
    this.currentToDate = dateRange.toDate;
    this.slOffset = 0;
    this.listExclusiveStartKey = [];
    this.currentExclusiveStartKey = this.lastExclusiveStartKey = null;
    await this.handleLoadActivity({
      pageNumber: 1,
      isInitialLoad: false,
      isPrevious: false,
      isNext: false,
      tenantId: this.tenantId,
      userId: this.userId,
      title: this.title,
      fromDate: dateRange.fromDate,
      toDate: dateRange.toDate,
      limit: this.defaultLimit,
      itemState: this.itemState,
    });
  }

  async previousPage() {
    if (!this.currentExclusiveStartKey) return;
    const exclusiveStartKey = this.listExclusiveStartKey.pop();
    await this.handleLoadActivity({
      pageNumber: this.pageNumber - 1,
      isInitialLoad: false,
      isPrevious: true,
      isNext: false,
      tenantId: this.tenantId,
      userId: this.userId,
      title: this.title,
      fromDate: this.currentFromDate,
      toDate: this.currentToDate,
      limit: this.defaultLimit,
      itemState: this.itemState,
      exclusiveStartKey: exclusiveStartKey,
    });
  }

  async nextPage() {
    if (!this.currentExclusiveStartKey) return;
    await this.handleLoadActivity({
      pageNumber: this.pageNumber + 1,
      isInitialLoad: false,
      isPrevious: false,
      isNext: true,
      tenantId: this.tenantId,
      userId: this.userId,
      title: this.title,
      fromDate: this.currentFromDate,
      toDate: this.currentToDate,
      limit: this.defaultLimit,
      itemState: this.itemState,
      exclusiveStartKey: this.currentExclusiveStartKey,
    });
  }

  async handleLoadActivity(parms: HandleLoadFileActivityPayload): Promise<void> {
    await clearAllActivityUpdateRepeatInterval();
    this.isFileActivityLoading = true;
    this.viewFileActivity = true;
    this.repeatExclusiveStartKey = parms.exclusiveStartKey || null;

    if (parms.isPrevious) parms.isNext = false;
    if (parms.isNext) parms.isPrevious = false;

    try {
      const response = await this.getActivity({
        tenantId: parms.tenantId,
        userId: parms.userId,
        title: parms.title,
        fromDate: parms.fromDate,
        toDate: parms.toDate,
        limit: parms.limit,
        exclusiveStartKey: parms.exclusiveStartKey,
        itemState: parms.itemState,
      });
      if (response.status !== 200) throw 'Cannot load user file history';
      this.fileActivity = response.data.data.results;
      this.repeat();
      if (isEmpty(this.fileActivity)) {
        EventBus.$emit(EVENTS.SHOW_TOAST, 'User does not have any file history.', TOAST.INFO);
        this.isEnd = true;
        await clearAllActivityUpdateRepeatInterval();
      }

      if (parms.isInitialLoad) {
        this.isEnd = true;
      } else {
        if (!response.data.data.exclusiveStartKey) {
          this.isEnd = true;
          if (parms.isNext) this.listExclusiveStartKey.push(this.lastExclusiveStartKey!);
        } else {
          this.isEnd = false;
          if (parms.isPrevious) this.lastExclusiveStartKey = parms.exclusiveStartKey!;
          else {
            this.listExclusiveStartKey.push(this.lastExclusiveStartKey!);
            this.lastExclusiveStartKey = this.currentExclusiveStartKey;
          }
          this.currentExclusiveStartKey = response.data.data.exclusiveStartKey;
        }
      }

      this.pageNumber = parms.pageNumber;
      if (this.pageNumber <= 1) this.lastExclusiveStartKey = null;
      if (parms.limit) {
        if (parms.isNext) this.slOffset += parms.limit;
        if (parms.isPrevious) this.slOffset -= parms.limit;
      }
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Cannot load user file history', TOAST.WARNING);
      await clearAllActivityUpdateRepeatInterval();
    } finally {
      this.isFileActivityLoading = false;
    }
  }

  async getActivity(parms: GetFileActivityPayload) {
    if (isEmpty(parms.itemState)) delete parms.itemState;
    if (isEmpty(parms.userId)) delete parms.userId;
    if (isEmpty(parms.title)) delete parms.title;
    if (isEmpty(parms.fromDate)) delete parms.fromDate;
    if (isEmpty(parms.toDate)) delete parms.toDate;
    const serviceLinks = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
    const response = await post(serviceLinks.itemHistory, { data: parms });

    return response;
  }
}
