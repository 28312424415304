






import { Mapping, MdfType } from '@/common/xmpUtils';
import store, { GettersTypes } from '@/store';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TextComponent extends Vue {
  @Prop() readonly mdf!: MdfType;
  @Prop() readonly itemLabel!: string;
  @Prop() readonly disableFlag!: boolean;

  textContent: string | null = null;

  get vText() {
    return this.textContent !== null ? this.textContent : this.textValue;
  }

  get textValue() {
    return this.dataObject[this.mdf.fieldId] as string;
  }

  set vText(text: string) {
    this.textContent = text;
  }

  get dataObject() {
    return store.getters[GettersTypes.GET_METADATA_FIELD_VALUES];
  }

  get textLabel() {
    if (this.mdf.required) return `${this.itemLabel}*`;
    return this.itemLabel;
  }

  textEvent() {
    const data: Mapping = {
      value: this.textContent ?? this.textValue,
      id: this.mdf.fieldId,
    };
    this.$emit('textData', data);
  }
}
