import { JsonData, MimirMetadataDto } from '@qmu/common/dto/MetadataModels';

export const convertTomimirFform = (jsonData: JsonData, mdfId: string) => {
  const metadata: MimirMetadataDto = {
    metadata: {
      formData: jsonData.formData,
      formId: mdfId,
    },
  };
  return metadata;
};

export const addDefaults = (jsonFormData: JsonData) => {
  const data: JsonData = {
    formData: {},
  };

  const keys = Object.keys(jsonFormData.formData);
  for (const key of keys) {
    if (key === 'mediaCreatedOn' || key === 'createdOn') {
      const newKey = `default_${key}`;
      data.formData[newKey] = jsonFormData.formData[key];
    } else {
      data.formData[key] = jsonFormData.formData[key];
    }
  }
  return data;
};

export const getEmailFromXMP = (jsonData: any) => {
  try {
    return jsonData['x:xmpmeta']['RDF']['Description']['Iptc4xmpCore:CreatorContactInfo']['_attributes']['Iptc4xmpCore:CiEmailWork'];
  } catch (err) {
    return null;
  }
};
