












import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DialogRequest } from '@/dialog';
import { components } from './dialogs';

@Component
export default class DialogFactory extends Vue {
  @Prop() req!: DialogRequest;

  get comp() {
    return components[this.req.dialogType];
  }
}
