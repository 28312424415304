import store, { GettersTypes } from '../store';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { ItemCreate } from '@qmu/common/dto/itemModels';
import { post } from '@/common/rest';
import { getMetaDataFromFile } from './fileUtilities';

export const createItem = async (item: ItemCreate) => {
  const { items: url } = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;

  return await post(url, {
    data: { item },
  });
};

export const createUpload = async (itemId: string) => {
  const { itemUpload: url } = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  return await post(url, {
    data: {
      itemId,
    },
  });
};

export const createSignedUrl = async (itemId: string, partNumber: number) => {
  const { itemUploadPartsSignUrls: url } = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  return await post(url, {
    data: {
      itemId,
      partNumber,
    },
  });
};

export interface CompletedPart {
  ETag: string;
  PartNumber: number;
}

export const completeMultipartUpload = async (itemId: string, parts: CompletedPart[]) => {
  const { itemMultipartUploadComplete: url } = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  return await post(url, {
    data: {
      itemId,
      parts,
      itemMetaData: getMetaDataFromFile(),
    },
  });
};
