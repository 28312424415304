import { GetGroupsOfUserPayload, GetUsersInGroupPayload, AssignUserToGroupPayload, RemoveUserFromGroupPayload, DeleteUserAccountPayload } from '@qmu/common/dto/UserInfoDto';
import { AdminListGroupsForUserResponse, ListUserInGroupResponse } from '@qmu/common/dto//UserInfoDto';
import { ClientConfig } from '@qmu/common/dto//ClientConfigDto';
import { ServiceLinks } from '@qmu/common/dto//ServiceDocumentDtos';
import { post } from './rest';
import store, { GettersTypes } from '@/store';
import { toString } from '@qmu/common/util/general';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
export interface UserDataModel {
  username: string;
  enabled: string;
  userCreatedAt: string;
  userStatus: string;
  emailVerified?: string;
  email?: string;
  groups?: Array<string>;
  userId?: string;
  tenantId?: string;
}
export interface UserPoolDataModel {
  getUsersOfGroup(groupName: string): Promise<Array<UserDataModel>>;
  getGroupsOfUser(username: string): Promise<Array<string>>;
  assignUserToGroup(username: string, groupName: string, tenantId: string, userId: string): Promise<boolean>;
  removeUserFromGroup(username: string, groupName: string, tenantId: string, userId: string): Promise<boolean>;
  deleteUserAccountWithData(username: string, tenantId: string, userId: string): Promise<boolean>;
}
export class UserPoolData implements UserPoolDataModel {
  serviceLinks: ServiceLinks | null = null;
  clientConfig: ClientConfig | null = null;

  constructor() {
    this.serviceLinks = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
    this.clientConfig = store.getters[GettersTypes.GET_CLIENT_CONFIGS] as ClientConfig;
  }

  async getUsersOfGroup(groupName: string): Promise<Array<UserDataModel>> {
    let users: Array<UserDataModel> = [];
    if (!(this.clientConfig && this.serviceLinks)) return users;
    const payload: GetUsersInGroupPayload = {
      groupName: groupName,
      userPoolId: this.clientConfig.userPoolId,
    };

    try {
      const resp = await post(this.serviceLinks.getUsersOfGroup, { data: payload });
      if (!resp.data.data) return users;
      const userListInGroup: ListUserInGroupResponse | null = resp.data.data;
      if (!userListInGroup?.Users) return users;
      for (const user of userListInGroup.Users) {
        const info: UserDataModel = {
          username: toString(user.Username),
          enabled: toString(user.Enabled),
          userCreatedAt: toString(user.UserCreateDate),
          userStatus: toString(user.UserStatus),
        };

        if (!user.Attributes) return users;
        for (const attribute of user.Attributes) {
          switch (attribute.Name) {
            case 'email':
              info.email = attribute.Value;
              break;
            case 'email_verified':
              info.emailVerified = attribute.Value;
              break;
            default:
              break;
          }
        }
        users.push(info);
      }
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to get Users from group', TOAST.ERROR);
    }
    return users;
  }

  async getGroupsOfUser(username: string): Promise<Array<string>> {
    if (!(this.clientConfig && this.serviceLinks)) return [];
    const payload: GetGroupsOfUserPayload = {
      username: username,
      userPoolId: this.clientConfig.userPoolId,
    };

    try {
      const resp = await post(this.serviceLinks.getGroupsOfUser, { data: payload });
      const memberOfGroups: string[] | null = resp.data.data;
      return memberOfGroups ?? [];
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to get groups of current user', TOAST.ERROR);
      return [];
    }
  }

  async assignUserToGroup(username: string, groupName: string, tenantId: string, userId: string): Promise<boolean> {
    if (!(this.clientConfig && this.serviceLinks)) return false;
    const payload: AssignUserToGroupPayload = {
      username,
      groupName,
      userPoolId: this.clientConfig.userPoolId,
      tenantId,
      userId,
    };

    try {
      const resp = await post(this.serviceLinks.assignGroupToUser, { data: payload });
      if (!resp.data.data) throw new Error('Failed to assign group');
      return resp.data.data;
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to assign group', TOAST.ERROR);
      return false;
    }
  }

  async removeUserFromGroup(username: string, groupName: string, tenantId: string, userId: string): Promise<boolean> {
    if (!(this.clientConfig && this.serviceLinks)) return false;
    const payload: RemoveUserFromGroupPayload = {
      username,
      groupName,
      userPoolId: this.clientConfig.userPoolId,
      tenantId,
      userId,
    };

    try {
      const resp = await post(this.serviceLinks.removeGroupFromUser, { data: payload });
      if (!resp.data.data) throw new Error('Failed to remove group');
      return resp.data.data;
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to remove group', TOAST.ERROR);
      return false;
    }
  }

  async deleteUserAccountWithData(username: string, tenantId: string, userId: string): Promise<boolean> {
    if (!(this.clientConfig && this.serviceLinks)) return false;
    const payload: DeleteUserAccountPayload = {
      username,
      userId,
      userPoolId: this.clientConfig.userPoolId,
      userTenantId: tenantId,
    };
    try {
      const resp = await post(this.serviceLinks.deleteUserAccount, { data: payload });
      return resp.data.data ? true : false;
    } catch (error) {
      return false;
    }
  }
}
