
















import { Mapping, MdfType } from '@/common/xmpUtils';
import store, { GettersTypes } from '@/store';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CheckBoxComponent extends Vue {
  @Prop() readonly mdf!: MdfType;
  @Prop() readonly itemLabel!: string;
  @Prop() readonly disableFlag!: boolean;

  isChecked: boolean | null = false;

  get vCheck() {
    return this.isChecked ?? this.dataObject[this.mdf.fieldId];
  }

  set vCheck(status: boolean) {
    this.isChecked = status;
  }

  get dataObject() {
    return store.getters[GettersTypes.GET_METADATA_FIELD_VALUES];
  }

  get vCheckLabel() {
    return this.mdf.required ? `${this.itemLabel}*` : this.itemLabel;
  }

  vCheckEvent() {
    const data: Mapping = {
      value: this.isChecked ?? this.dataObject[this.mdf.fieldId],
      id: this.mdf.fieldId,
    };
    this.$emit('checkData', data);
  }
}
