import * as rest from '@/common/rest';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { PostRequestConfigType } from '@qmu/common/apis/api';
import { SubscriptionPayload, SubscriptionStatusQueryData, SESEmailIdentityPayload, DpaEmailTemplatePayload, EmailTemplateType, DpaEmailTemplateData, EmailReceipentType, Emails } from '@qmu/common/dto/notificationModels';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import NotificationType from '@qmu/common/enum/NotificationType';
import { AxiosResponse } from '@qmu/common/../node_modules/axios';

export const toggleSubscriptionStatus = (address: string, type: NotificationType, status: boolean): Promise<AxiosResponse> => {
  store.dispatch(ActionsTypes.SET_EMAIL_SUBSCRIPTION_INFO, status);
  const serviceLinks = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  const payload: SubscriptionPayload = {
    address,
    type,
    status,
  };
  const requestConfig: PostRequestConfigType = {
    data: payload,
    method: 'POST',
  };
  return rest.post(serviceLinks.toggleSubscription, requestConfig);
};

export const getSubscriptionStatus = async (tenantId: string, userId: string): Promise<AxiosResponse> => {
  const serviceLinks = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  const queryString: SubscriptionStatusQueryData & Record<string, string> = {
    tenantId,
    userId: userId,
  };
  const requestLink = `${serviceLinks.getSubscriptionInfo}?${new URLSearchParams(queryString).toString()}`;
  const subscriptionData = await rest.get(requestLink);
  if (subscriptionData?.status !== 200) throw 'API error!';
  const statusInfo = subscriptionData.data?.data?.status;
  if (!statusInfo) throw "You haven't subscribe to email notification";
  return statusInfo;
};

export const sendTemplatedEmail = async (receipentType: EmailReceipentType, emailType: EmailTemplateType, templateData: DpaEmailTemplateData, recipientEmails: Emails): Promise<AxiosResponse> => {
  const serviceLinks = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  const sendEmailPayload: DpaEmailTemplatePayload = {
    receipentType,
    templateType: emailType,
    recipientEmails,
    templateData,
  };

  return rest.post(serviceLinks.sendEmail, { data: sendEmailPayload });
};

export const fetchSESIdentities = async (tenantId?: string | null): Promise<AxiosResponse> => {
  const serviceLinks = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  return rest.get(serviceLinks.getSESIdentities, { params: { tenantId } });
};

export const addNewIdentity = async (email: string): Promise<AxiosResponse> => {
  const serviceLinks = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  const sesIdentityData: SESEmailIdentityPayload = {
    email,
  };
  const requestConfig: PostRequestConfigType = {
    method: 'POST',
    data: sesIdentityData,
  };
  return rest.post(serviceLinks.addNewIdentity, requestConfig);
};

export const changeFromEmailAddress = async (email: string): Promise<AxiosResponse> => {
  const serviceLinks = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  const sesChangeIdentityData: SESEmailIdentityPayload = {
    email,
  };
  const requestConfig: PostRequestConfigType = {
    method: 'POST',
    data: sesChangeIdentityData,
  };
  return rest.post(serviceLinks.changeSenderEmail, requestConfig);
};
