




import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ActivityStatus } from '@qmu/common/dto/activityModels';

@Component
export default class SystemActivityStatus extends Vue {
  @Prop({ default: false }) isClear!: boolean;
  itemStateList = Object.values(ActivityStatus);
  selectedStatus = '';

  @Watch('isClear')
  onDataChanged() {
    this.selectedStatus = '';
  }

  changeSelectedStatus() {
    this.$emit('triggerSelectedStatus', this.selectedStatus);
  }
}
