import { isFunction } from 'lodash';
import { toString, MessageValue } from './general';
/*
    Allow message to be lazy formatted, so that the code will not spend time
    formatting the message unless the invariant actually got broken, and we
    therefore need a message to report.
*/
type MessageFormatter = MessageValue | (() => MessageValue);

export function invariant(value: any, message: MessageFormatter): asserts value {
  if (!value) {
    const msg = toString(isFunction(message) ? message() : message);
    console.assert(value, toString(msg));
    throw new Error(msg);
  }
}

export function assert(condition: any, message: MessageFormatter): asserts condition {
  /*
        Warning: WebPack magic

        Webpack will make process.env.NODE_ENV code work in the browser.
    */
  if (process.env.NODE_ENV === 'development') {
    const value = isFunction(condition) ? condition() : condition;
    invariant(value, message);
  }
}

/*
    Evaluator will only be run and checked when in development mode.
*/
export async function asyncAssert(evaluator: () => Promise<any>, message: MessageFormatter): Promise<void> {
  /*
        Warning: WebPack magic

        Webpack will make process.env.NODE_ENV code work in the browser.
    */
  if (process.env.NODE_ENV === 'development') {
    const value = await evaluator();
    invariant(value, message);
  }
}
