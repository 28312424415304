














import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FilterBtn extends Vue {
  triggerClick() {
    this.$emit('triggerApplyFilterBtn');
  }
}
