import { invariant } from '@qmu/common/util/invariant';
import EventBus, { EVENTS } from '@/eventBus';
import { UserDataModel } from './common/userPoolData';

const showPleaseWaitDialog = (message: string) => {
  const req: PleaseWaitRequest = {
    dialogType: DialogType.WAIT,
    ongoingMessage: message,
  };
  requestDialog(req);
};

const showGroupHandlingDialog = (userDataModel: UserDataModel) => {
  const req: GroupHandlingRequest = {
    dialogType: DialogType.GROUP_HANDLING,
    userDataModel: userDataModel,
  };
  requestDialog(req);
};

const hidePleaseWaitDialog = () => {
  hideDialog(DialogType.WAIT);
};

const hideGroupHandlingDialog = () => {
  hideDialog(DialogType.GROUP_HANDLING);
};

const showSimpleDialog = (dialogProps: BaseDialogProps, actionFn?: () => void, secondaryActionFn?: () => void, cancelFn?: () => void) => {
  const req: SimpleDialogRequest = {
    dialogType: DialogType.SIMPLE,
    dialogProps: {
      closeOnActionClick: true,
      ...dialogProps,
    },
    callback: async response => {
      if (response === 'ok') {
        if (actionFn) {
          actionFn();
        }
      } else if (response === 'secondaryOk') {
        invariant(secondaryActionFn, 'Expected function to call on secondary action');
        secondaryActionFn();
      } else if (response === 'cancel') {
        cancelFn && cancelFn();
      }
    },
  };
  requestDialog(req);
};

const showSimpleAsyncDialog = async (dialogProps: BaseDialogProps, actionFn: () => Promise<void>, secondaryActionFn?: () => Promise<void>, bootstrappingFn?: () => Promise<any>) => {
  const req: SimpleDialogRequest = {
    bootstrapping: bootstrappingFn !== undefined,
    loading: false,
    showDialog: true,
    dialogType: DialogType.SIMPLE,
    dialogProps,
    callback: async response => {
      if (response === 'ok') {
        invariant(actionFn, 'Expected function to call on ok click');
        req.loading = true;
        await actionFn();
        req.loading = false;
        req.showDialog = false;
      } else if (response === 'secondaryOk') {
        invariant(secondaryActionFn, 'Expected function to call on secondary action');
        req.loading = true;
        await secondaryActionFn();
        req.loading = false;
        req.showDialog = false;
      }
    },
  };
  requestDialog(req);
  if (bootstrappingFn) {
    await bootstrappingFn();
    req.bootstrapping = false;
  }
};

const showCopyTextDialog = (objOrText: any, id?: string) => {
  const req: DialogCopyTextRequest = {
    dialogType: DialogType.COPY_TEXT,
    text: typeof objOrText === 'string' ? objOrText : JSON.stringify(objOrText, null, 2),
    id,
    dialogProps: {
      header: 'Text',
      icon: 'title',
    },
  };
  requestDialog(req);
};

export const requestDialog = (req: DialogRequest | DialogType) => {
  EventBus.$emit(EVENTS.SHOW_DIALOG, typeof req === 'string' ? { dialogType: req } : req);
};

const hideDialog = (type: DialogType) => {
  EventBus.$emit(EVENTS.HIDE_DIALOG, type);
};

/**
 * This should be kept in sync with the props BaseDialog accepts.
 */
export interface BaseDialogProps {
  icon: string;
  header: string;

  // These 2 are mutually exclusive - baseText takes precedent.
  baseText?: string;
  baseHtml?: string;

  infoOnly?: boolean;
  noAutoClose?: boolean;
  closeOnActionClick?: boolean;
  disabledTooltip?: string;
  disableAction?: boolean;
  disableSecondaryAction?: boolean;
  actionText?: string;
  cancelText?: string;
  maxWidth?: number;
  subHeader?: string;
  mimirIcon?: string;
  bootstrapping?: boolean;
  secondaryActionText?: string;
}

type ValidationFunction = (val: string) => true | string;

export interface EditDialogProps {
  startValue: string;
  lazyValidation?: boolean;
  placeholder?: string;
  rules?: ValidationFunction[];
  label?: string;
  errorMessage?: string;

  // If provided, will be slotted above the textfield. Remember to HTML escape values.
  subHeaderHtml?: string;
  // Allows for listening to the edited value from the outside of the dialog.
  valueListener?: (val: string) => void;
}

export enum DialogType {
  SIMPLE = 'Simple',
  WAIT = 'PleaseWait',
  COPY_TEXT = 'CopyText',
  GROUP_HANDLING = 'groupHandling',
}

export interface DialogRequest {
  dialogType: DialogType;
  dialogProps?: BaseDialogProps;

  // If you want to make use of controlling loading and showing of the dialog
  // manually from the outside, remember to initialise these parameters in the request
  // or Vue reactivity won't kick in.
  loading?: boolean;
  showDialog?: boolean;
  bootstrapping?: boolean;

  // The callback that will be invoked when the user presses confirm. If a specific shape
  // of the function is required, this should be defined in the extending request, eg: ('ok' | 'cancel') => void),
  // if the dialog only returns 'ok' or 'cancel'.
  // In the case of Simple and Edit requests, the provided function should be awaitable if you want to benefit of automatic
  // loading before closing flow.
  callback?: (_: any) => void;
}

export interface DialogCopyTextRequest extends DialogRequest {
  dialogType: DialogType.COPY_TEXT;
  text: string;
  id?: string; // If provided, will add a copy button for the ID
}

export interface PleaseWaitRequest extends DialogRequest {
  dialogType: DialogType.WAIT;
  ongoingMessage: string;
}

export interface GroupHandlingRequest extends DialogRequest {
  dialogType: DialogType.GROUP_HANDLING;
  userDataModel: UserDataModel;
}

export interface EditDialogRequest extends DialogRequest {
  callback: (val: string) => void;
  editDialogProps: EditDialogProps;
}

export interface SimpleDialogRequest extends DialogRequest {
  callback: (val: 'ok' | 'secondaryOk' | 'cancel') => void;
}

/**
 * This object is made available on the Vue prototype: $dialog.
 *
 * This is meant as an entry point to trigger generic dialog functions - do not use this single fire features, but keep
 * the object clean for functions that are reused many places.
 *
 * The comments are added here, because they do not get passed through automatically if placed on the original function.
 */
export const dialog = {
  /**
   * Request to show a dialog.
   * @param req The request that determines which dialog will be shown.
   */
  requestDialog: requestDialog,
  /**
   * Show a simple dialog with a confirm and cancel button, the text can be configured
   * through dialogProps.
   *
   * @param dialogProps The props to configure the dialog
   * @param actionFn The synchronous callback to call when the user confirms the dialog, if any.
   */
  showSimpleDialog: showSimpleDialog,

  /**
   * Show a simple dialog with a confirm and cancel button, the text can be configured
   * through dialogProps. The provided promise will be awaited, causing the dialog action button to spin until resolved.
   * It will close upon resolving.
   *
   * @param dialogProps The props to configure the dialog
   * @param actionFn The promise to await when the user confirms the dialog, if any.
   * @param secondaryFn Optionally provide a function to call when the user presses the secondary action button
   * @param bootstrappingFn Optionally provide a promise that will be awaited before the dialog contents are shown. The dialog
   *                        will show a loading spinner until the promise resolves.
   */
  showSimpleAsyncDialog: showSimpleAsyncDialog,
  showPleaseWaitDialog: showPleaseWaitDialog,
  hidePleaseWaitDialog: hidePleaseWaitDialog,
  showGroupHandlingDialog: showGroupHandlingDialog,
  hideGroupHandlingDialog: hideGroupHandlingDialog,

  /**
   * Useful for debugging, to show copyable text.
   *
   * Can for example be used to stringify an object in a dialog, when in
   * environments where it's difficult (premiere).
   *
   * Supply id if you want an extra copy to clipboard button for this value
   */
  showCopyTextDialog: showCopyTextDialog,
};

export type Dialog = typeof dialog;
