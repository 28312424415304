




















import { Mapping, MdfType } from '@/common/xmpUtils';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ChoiceComponent extends Vue {
  @Prop() readonly mdf!: MdfType;
  @Prop() readonly itemLabel!: string;
  @Prop() readonly disableFlag!: boolean;

  items: Array<string> = [];
  itemsValue: Array<string> = [];
  defaultSelected: { value: string; label: string } | null = null;

  get mdfId() {
    return `#${this.mdf.fieldId}`;
  }

  get dataObject() {
    return store.getters[GettersTypes.GET_METADATA_FIELD_VALUES];
  }

  get metadataXmpFlag() {
    return store.getters[GettersTypes.GET_METADATA_FROM_XMP_FLAG];
  }

  set metadataXmpFlag(value: boolean) {
    store.dispatch(ActionsTypes.SET_METADATA_XMP_FLAG, value);
  }

  choiceValueById(id: string) {
    if (this.metadataXmpFlag) this.getValueById(id, 'label');
    else this.getValueById(id, 'value');
  }

  private getValueById(id: string, fieldName: string) {
    let isSet = false;
    if (this.mdf.alternatives) {
      for (const item of this.mdf.alternatives) {
        if (fieldName === 'label') {
          if (item.label === this.dataObject[id]) {
            isSet = true;
            this.defaultSelected = { value: item.value, label: item.label };
            break;
          }
        } else {
          if (item.value === this.dataObject[id]) {
            isSet = true;
            this.defaultSelected = { value: item.value, label: item.label };
            break;
          }
        }
      }
    }
    if (!isSet) this.setDefaultValue();
  }

  setDefaultValue() {
    if (this.mdf && typeof this.mdf.defaultValue === 'string') {
      for (let index in this.mdf.alternatives) {
        if (this.mdf.alternatives[index].value === this.mdf.defaultValue) {
          this.defaultSelected = {
            label: this.mdf.alternatives[index].label,
            value: this.mdf.alternatives[index].value,
          };
          break;
        }
      }
    }
  }

  async mounted() {
    this.setDefaultValue();
    if (this.dataObject[this.mdf.fieldId]) {
      this.choiceValueById(this.mdf.fieldId);
      this.mappedData(this.dataObject[this.mdf.fieldId]);
      return;
    }
  }

  private findValue(label: string) {
    for (const item of this.mdf.alternatives) {
      if (label === item.label) return item.value;
    }
    return label;
  }

  get choiceLabel() {
    if (this.mdf.required) return `${this.itemLabel}*`;
    return this.itemLabel;
  }

  choiceEvent(selectedvalue: string) {
    this.mappedData(selectedvalue);
  }

  private mappedData(selectedValue: string) {
    const data: Mapping = {
      value: this.findValue(selectedValue),
      id: this.mdf.fieldId,
    };
    this.$emit('choiceData', data);
  }
}
