








import store, { GettersTypes } from '@/store';
import { TenantUserListMap } from '@qmu/common/dto/userDataDto';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class UserList extends Vue {
  @Prop({ default: false }) isClear!: boolean;
  @Prop({ default: '' }) deletedUserName!: string;

  selectedUser = '';

  get reactiveDeletedUserName() {
    return this.deletedUserName;
  }

  set reactiveDeletedUserName(value: string) {
    this.deletedUserName = value;
  }

  @Watch('deletedUserName')
  removedEntryOfDeletedUser() {
    if (!this.reactiveDeletedUserName) return;
    const selectedTenant = store.getters[GettersTypes.GET_SELECTED_TENANT] as string;
    const emailUseIdMap = store.getters[GettersTypes.GET_TENANT_USER_LIST_EMAIL_USER_ID_MAP] as TenantUserListMap;
    const userNameUseIdMap = store.getters[GettersTypes.GET_TENANT_USER_LIST_USER_NAME_USER_ID_MAP] as TenantUserListMap;
    const useIdEmailMap = store.getters[GettersTypes.GET_TENANT_USER_LIST_USER_ID_USER_EMAIL_MAP] as TenantUserListMap;

    delete emailUseIdMap[selectedTenant][useIdEmailMap[selectedTenant][userNameUseIdMap[selectedTenant][this.reactiveDeletedUserName]]];
    delete userNameUseIdMap[selectedTenant][this.reactiveDeletedUserName];
    delete useIdEmailMap[selectedTenant][userNameUseIdMap[selectedTenant][this.reactiveDeletedUserName]];

    const email = Object.keys(emailUseIdMap[selectedTenant]);
    const userName = Object.keys(userNameUseIdMap[selectedTenant]);
    const userId = Object.keys(useIdEmailMap[selectedTenant]);

    this.usersData = [...this.sortList(email), ...this.sortList(userName), ...this.sortList(userId)];
  }

  @Watch('isClear')
  onDataChanged() {
    this.selectedUser = '';
  }

  usersData: string[] = [];

  get userList() {
    const selectedTenant = store.getters[GettersTypes.GET_SELECTED_TENANT] as string;
    const emailUseIdMap = store.getters[GettersTypes.GET_TENANT_USER_LIST_EMAIL_USER_ID_MAP] as TenantUserListMap;
    const userNameUseIdMap = store.getters[GettersTypes.GET_TENANT_USER_LIST_USER_NAME_USER_ID_MAP] as TenantUserListMap;
    const useIdEmailMap = store.getters[GettersTypes.GET_TENANT_USER_LIST_USER_ID_USER_EMAIL_MAP] as TenantUserListMap;

    const email = Object.keys(emailUseIdMap[selectedTenant]);
    const userName = Object.keys(userNameUseIdMap[selectedTenant]);
    const userId = Object.keys(useIdEmailMap[selectedTenant]);
    this.usersData = [...this.sortList(email), ...this.sortList(userName), ...this.sortList(userId)];
    return this.usersData;
  }

  changeSelected() {
    this.$emit('triggerSelectedUser', this.selectedUser);
  }

  sortList(list: string[]) {
    return list.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
  }
}
